import React, {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import PageLoading from '../../loading/PageLoading'
import {EXPLORE_EMAILS_DATA as EmailsData} from '../../../modules/auth/redux/DEMO_DATA'
import {FilterSelectedEmail, SearchEmail, SetDataEmails, SortEmail} from '../../../modules/auth'
import useGetFilterOptionsEmail from '../../../../Utils/useGetFilterOptionsEmail'
import SearchAndSort from '../../components/SearchAndSort'
import usePermission from '../../../../Utils/usePermission'
import {SelectedType} from '../../../modules/types/filters'
import Filters from '../Filters'
import {Button} from 'react-bootstrap'

const SortOptions: any[] = []

/**
 * Email component for displaying and managing email data
 * @returns {JSX.Element} Email component
 */
export default function Email () {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const [showFilters, setShowFilters] = useState(false)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const {isPermission, getPermission} = usePermission()
  const getFilterOptionsEmail = useGetFilterOptionsEmail()

  // Redux selectors
  const emailsData = useSelector((state: RootState) => state.root.data.dataEmails)
  const filterOptionEmail = useSelector(
    (state: RootState) => state?.root?.filterOption?.email.options
  )
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.email.selected)
  const sort = useSelector((state: RootState) => state.root.filterOption.email)
  const search = useSelector((state: RootState) => state.root.filterOption.email.search)

  /**
   * Creates sort parameters for API request
   * @param {string} sortProps - Sort properties
   * @returns {string} Formatted sort string for API
   */
  function createPropsSort (sortProps: string): string {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }

  /**
   * Creates search parameters for API request
   * @returns {string} Formatted search string for API
   */
  function createPropsSearch (): string {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }

  /**
   * Handles page change in pagination
   * @param {Object} event - Page change event
   * @param {number} event.selected - Selected page number
   */
  const handlePageClick = (event: {selected: number}) => {
    fetchEmailData({query: `?page=${event.selected + 1}`})
  }

  /**
   * Fetches email data from API
   * @param {Object} options - Fetch options
   * @param {string} [options.query='?page=1'] - Query string
   * @param {SelectedType|null} [options.filterProps=null] - Filter properties
   * @param {string} [options.sort=''] - Sort string
   */
  const fetchEmailData = async ({
    query = '?page=1',
    filterProps = null,
    sort = '',
  }: {
    query?: string
    filterProps?: null | SelectedType
    sort?: string
  }) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/email/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataEmails(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/email/${query} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  /**
   * Handles selection of filter items
   * @param {string} items - Filter category
   * @param {string} item - Selected item
   */
  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(FilterSelectedEmail(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchEmailData({query: '?page=1', filterProps: copy})
    }
  }

  /**
   * Creates filter parameters for API request
   * @param {SelectedType|null} [props] - Filter properties
   * @returns {string} Formatted filter string for API
   */
  function createPropsFilter (props?: null | SelectedType): string {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      return text.join('')
    } else {
      const keys = Object.keys(selected || {})
      const text = keys.map((key) => {
        if (selected?.[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      return text.join('')
    }
  }

  useEffect(() => {
    const nowDate = new Date().getTime()
    if (emailsData?.expireTime && emailsData.expireTime >= nowDate) {
    } else {
      fetchEmailData({})
    }
    if (!filterOptionEmail && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsEmail()
    }
  }, [])

  return (
    <>
      <div
        className={`w-100  p-4 bg-body overflow-hidden position-relative ${loading && 'skeleton'}`}
        style={{maxWidth: 'calc(100% - 100px)'}}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'Email Address'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div className=' w-100 ps-3 '>
            <div className='d-flex align-items-center justify-content-between gap-5'>
              <SearchAndSort
                className={'mb-5 mw-75'}
                search={search}
                setSearch={SearchEmail}
                sort={sort}
                setSort={SortEmail}
                getData={fetchEmailData}
                sortOption={SortOptions}
                searchOption={Object.keys(filterOptionEmail || {})}
              />
              <Button
                className='mb-5'
                onClick={() => {
                  setShowFilters(true)
                }}
              >
                                {intl.formatMessage({id: 'Filter'})}

              </Button>
            </div>
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>
                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-50 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Email'})}</span>
                      </div>
                    </div>
                    <div className='w-50 p-1 h-auto justify-content-center'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Username'})}</span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {(emailsData?.data && emailsData?.data?.results?.length
                  ? emailsData.data.results
                  : EmailsData.results
                )?.map((item, index: number) => (
                  <div
                    style={!emailsData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                    key={index}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center  mb-3`}
                  >
                    <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div className='w-50 p-1 d-flex  h-auto justify-content-center'>
                        {' '}
                        {item.email}{' '}
                      </div>
                      <div className='w-50 p-1 h-auto d-flex justify-content-center'>
                        {item.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={emailsData?.data?.total_pages || 1}
                  forcePage={
                    emailsData?.data?.current_page ? emailsData?.data?.current_page - 1 : 0
                  }
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Filters
        show={showFilters}
        setShow={setShowFilters}
        filterSelected={FilterSelectedEmail}
        selected={selected}
        get={fetchEmailData}
        filterOptionDomains={filterOptionEmail}
      />
    </>
  )
}
