import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

interface DnsRecordProps {
  title: string
  recordType: 'a' | 'mx' | 'ns'
  records?: any[]
  icon: string
  description: string
}

const DnsRecordSection: React.FC<DnsRecordProps> = ({
  title,
  recordType,
  records = [],
  icon,
  description,
}) => {
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState(false)
  const targetId = `kt_account_profile_details_${recordType.toUpperCase()}`

  return (
    <div className='card bg-secondary bg-opacity-15 border-0 mb-5 mw-100 '>
      <div
        className='card-header border-0 py-5 cursor-pointer px-4'
        role='button'
        onClick={() => setIsExpanded(!isExpanded)}
        data-bs-toggle='collapse'
        data-bs-target={`#${targetId}`}
        aria-expanded={isExpanded}
        aria-controls={targetId}
      >
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-40px me-4'>
              <KTSVG path={icon} className='svg-icon-2x image_change_theme2' />
            </div>
            <div className='d-flex flex-column'>
              <h3 className='fw-bold mb-1'>{title}</h3>
              <span className='text-muted fs-7'>{description}</span>
            </div>
          </div>
          <span className='ms-auto'>
            <KTSVG
              path='/media/icons/duotune/arrows/arr072.svg'
              className={`svg-icon-2 image_change_theme2 ${isExpanded ? 'rotate-180' : ''}`}
            />
          </span>
        </div>
      </div>

      <div id={targetId} className='collapse mw-100 px-4'>
        <div className='card-body pt-0 px-0'>
          {records?.length > 0 ? (
            <div className='d-flex flex-wrap gap-2'>
              {records.map((record, index) => (
                <div
                  key={`${recordType}-${index}`}
                  className='badge badge-light-primary d-inline-flex align-items-center px-4 py-2'
                >
                  <span className='fs-7 mw-100 text-wrap'>{record}</span>
                </div>
              ))}
            </div>
          ) : (
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon-2tx svg-icon-warning me-4 image_change_theme2'
              />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-semibold image_change_theme2'>
                  {intl.formatMessage({id: 'No Data'})}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface DnsRecordsCardProps {
  dnsData?: Array<
    Array<{
      a?: string[]
      mx?: string[]
      ns?: string[]
    }>
  >
}

const DnsRecordsCard: React.FC<DnsRecordsCardProps> = ({dnsData = []}) => {
  const intl = useIntl()

  const extractRecords = (type: 'a' | 'mx' | 'ns'): string[] => {
    return dnsData.flatMap((items) => items?.flatMap((item) => item?.[type] || [])).filter(Boolean)
  }

  const recordTypes = [
    {
      type: 'a',
      title: intl.formatMessage({id: 'A Records'}),
      icon: '/media/icons/duotune/technology/teh004.svg',
      description: intl.formatMessage({id: 'Address records (IPv4)'}),
    },
    {
      type: 'mx',
      title: intl.formatMessage({id: 'MX Records'}),
      icon: '/media/icons/duotune/communication/com010.svg',
      description: intl.formatMessage({id: 'Mail exchange records'}),
    },
    {
      type: 'ns',
      title: intl.formatMessage({id: 'NS Records'}),
      icon: '/media/icons/duotune/technology/teh008.svg',
      description: intl.formatMessage({id: 'Nameserver records'}),
    },
  ]

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5 px-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold '>{intl.formatMessage({id: 'DNS Records'})}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {intl.formatMessage({id: 'Domain name system records'})}
          </span>
        </h3>
      </div>

      <div className='card-body px-4'>
        {recordTypes.map((record) => (
          <DnsRecordSection
            key={record.type}
            title={record.title}
            recordType={record.type as 'a' | 'mx' | 'ns'}
            records={extractRecords(record.type as 'a' | 'mx' | 'ns')}
            icon={record.icon}
            description={record.description}
          />
        ))}
      </div>
    </div>
  )
}

export default DnsRecordsCard
