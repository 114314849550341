import React, { useCallback } from 'react'
import {Button} from 'react-bootstrap'
import { useIntl } from 'react-intl'
import {useNavigate} from 'react-router-dom'
/**
 * NotPermission - A component displayed when user lacks access permissions
 * 
 * @component
 * @description Renders a full-page overlay indicating insufficient permissions
 * 
 * @returns {React.ReactElement} Permission denied interface
 * 
 * @remarks
 * - Uses react-router-dom for navigation
 * - Supports internationalization via react-intl
 * 
 * @example
 * <NotPermission />
 */
export default function NotPermission () {
  const navigate = useNavigate()
  const intl = useIntl()
  const closeAllModals = useCallback(() => {
    // Get all modal backdrop elements
    const backdrops = document.querySelectorAll('.modal-backdrop');
    
    // Remove all backdrop elements
    backdrops.forEach(backdrop => {
      backdrop.remove();
    });
    
    // Get all modal elements
    const modals = document.querySelectorAll('.modal');
    
    // For each modal
    modals.forEach(modal => {
      // Remove the 'show' class
      modal.classList.remove('show');
      
      // Remove inline styles (Bootstrap adds display: block)
      (modal as HTMLElement).style.display = 'none';
      
      // Remove the 'modal-open' class from body to enable scrolling
      document.body.classList.remove('modal-open');
      
      // Remove the aria-modal attribute
      modal.setAttribute('aria-modal', 'false');
      
      // Set aria-hidden to true
      modal.setAttribute('aria-hidden', 'true');
    });
    
    // Remove any padding-right added to the body
    document.body.style.paddingRight = '';
    
    // Enable scrolling
    document.body.style.overflow = '';
  }, []);
  return (
    <div
      className='position-absolute d-flex justify-content-center m-0 p-0 mw-100 w-100 h-100'
      style={{
        zIndex: 99,
        backdropFilter: 'blur(4px)',
      }}
    >
      <div
        className='position-sticky d-flex flex-column align-items-center gap-10 p-10 card'
        style={{
          height: 'fit-content',
          width: '70%',
          maxWidth: 600,
          minWidth: 400,
          backgroundColor: '#76767670',
          top: 90,
        }}
      >
        <h1 className='fw-bold text-center'>
        {intl.formatMessage({id: 'You do not have permission to access this page'})}
          </h1>
        <Button
          onClick={() => {
            closeAllModals()
            navigate('/services')
          }}
        >
          {intl.formatMessage({id: 'Get access'})}
          
        </Button>
      </div>
    </div>
  )
}
