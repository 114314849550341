/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useIntl} from 'react-intl'
import { VulnerabilitiesOverTime } from '../../../../modules/types/asset'



/**
 * Default data to use when no data is provided
 */
const AlternativeData = {
  x: ['start', 'end'],
  y: [0, 0],
}

/**
 * ChartLine component for rendering a line chart
 * @param {Object} props - Component props
 * @param {VulnerabilitiesOverTime} props.data - Data for the chart
 */
const ChartLine = ({data = AlternativeData}: {data: VulnerabilitiesOverTime | undefined}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  /**
   * Generates chart options for ApexCharts
   * @param {number} height - Chart height
   * @param {string} labelColor - Color for labels
   * @param {string} baseColor - Base color for chart elements
   * @param {string} lightColor - Light color for chart elements
   * @param {Props} data - Chart data
   * @returns {ApexOptions} ApexCharts options
   */
  function getChartOptions (
    height: number,
    labelColor: string,
    baseColor: string,
    lightColor: string,
    data: VulnerabilitiesOverTime
  ): ApexOptions {
    const options: ApexOptions = {
      series: [
        {
          name: intl.formatMessage({id: 'Vulnerable Software'}),
          // Add a zero at the start if there's only one data point
          data: data? data.y.length === 1 ? [0, ...data.y] : data.y : AlternativeData.y,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        width: '100%',
        
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        // Add a 'start' label if there's only one data point
        categories:data? data.x.length === 1 ? ['start', ...data.x] : data.x:AlternativeData.x,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          show: false,
          position: 'front',
          stroke: {
            color: '#E4E6EF',
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      tooltip: {
        theme: 'dark',
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: number) {
            return val?.toString()
          },
        },
      },
      colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColors: [baseColor],
        strokeWidth: 3,
      },
    }
    return options
  }

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    // Create and render the chart
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(100, '#CDCDDE', '#F64E60', '#ff3c3c29', data)
    )
    if (chart) {
      chart.render()
    }

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data])

  return (
    <div
      ref={chartRef}
      className='card-rounded-bottom statistics-widget-3-chart flex-grow-1'
      style={{height: '100px'}}
    ></div>
  )
}

export default ChartLine

