const d1 = {
  nodes: [
    {
      id: '0.820945941404374',
      icon: 'http://stg-api.securest.ir/media/initialstart_e75d926d-8a78-4de5-b66d-f900f5a06a34.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'initial',
        label_fa: null,
        details:
          'The first node in every DAG, responsible for setting configurations and necessary variables',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
            {
              id: '01JJ6RTMPT464SWXE0XTHWHRXX',
              label: 'ip',
              color: '#ff0000',
            },
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
      position: {
        x: -232,
        y: 228.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
  ],
  connectors: [],
}
const d2 = {
  nodes: [
    {
      id: '0.820945941404374',
      icon: 'http://stg-api.securest.ir/media/initialstart_e75d926d-8a78-4de5-b66d-f900f5a06a34.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'initial',
        label_fa: null,
        details:
          'The first node in every DAG, responsible for setting configurations and necessary variables',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
            {
              id: '01JJ6RTMPT464SWXE0XTHWHRXX',
              label: 'ip',
              color: '#ff0000',
            },
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
      position: {
        x: -232,
        y: 228.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.7039105633799267',
      icon: null,
      abstract_config: {
        fields: [
          {
            name: 'scanner',
            type: 'string',
            required: true,
            label: 'Scanner',
            label_fa: 'اسکنر',
            description:
              'Defines the tool used for asset discovery. Users must choose between Amass or Subfinder for scanning.',
            description_fa:
              'ابزاری که برای کشف دارایی‌ها استفاده می‌شود را مشخص می‌کند. کاربران باید بین Amass یا Subfinder برای اسکن انتخاب کنند.',
            default: 'amass',
            inputType: 'select',
            options: [
              {
                label: 'amass',
                value: 'amass',
              },
              {
                label: 'subfinder',
                value: 'subfinder',
              },
            ],
          },
          {
            name: 'threads',
            type: 'number',
            required: true,
            label: 'Threads',
            label_fa: 'تعداد نخ‌ها',
            description:
              'Sets the number of concurrent threads for scanning. A higher number speeds up the process but consumes more resources.',
            description_fa:
              'تعداد نخ‌های همزمان برای اسکن را تنظیم می‌کند. تعداد بالاتر فرآیند را سریع‌تر می‌کند ولی منابع بیشتری مصرف می‌کند.',
            default: 10,
            inputType: 'number',
            min: 1,
            max: 100,
          },
          {
            name: 'dnsbruteforce',
            type: 'boolean',
            required: false,
            label: 'DNS Brute Force',
            label_fa: 'حمله براوت‌فورس DNS',
            description:
              'If enabled, performs a brute-force attack on DNS to discover hidden subdomains. If disabled, it skips this step.',
            description_fa:
              'اگر فعال باشد، حمله براوت‌فورس به DNS انجام می‌دهد تا زیر دامنه‌های مخفی کشف شوند. اگر غیرفعال باشد، این مرحله رد می‌شود.',
            default: false,
            inputType: 'checkbox',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'assets',
        label_fa: null,
        details: 'Identifies subdomains associated with a host',
        details_fa: 'زیردامنه‌های مرتبط با یک میزبان را شناسایی می‌کند.',
        long_description:
          'The Asset Discovery component identifies and catalogs digital assets, including domains, subdomains, IPs, and services. It helps map the attack surface and provides a foundational inventory for security assessments. Essential for ensuring no digital asset is overlooked in the protection strategy.',
        long_description_fa:
          'کامپوننت Asset Discovery دارایی‌های دیجیتال مثل دامنه‌ها، زیر دامنه‌ها و آی‌پی‌ها را شناسایی می‌کند. این ابزار نقشه‌ای جامع از سطح حمله ارائه می‌دهد. هدف آن تضمین عدم نادیده‌گیری هیچ دارایی دیجیتال است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQTW5FSJMS18NKQ1H67',
      position: {
        x: -88,
        y: 231,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
  ],
  connectors: [
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.7039105633799267',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.703910563379926701JJ6RTMPZMHY8S0Y20185A65G',
    },
  ],
}
const d3 = {
  nodes: [
    {
      id: '0.6551484174591504',
      icon: 'http://stg-api.securest.ir/media/dnsdns-removebg-preview_a8040e05-626f-41cf-aa60-202c78d69cc6.png',
      abstract_config: {
        fields: [
          {
            name: 'NameServers',
            type: 'string',
            required: false,
            label: 'Name Servers',
            label_fa: 'سرورهای نام',
            description:
              'Specifies the name server addresses to be used for DNS resolution. Multiple addresses should be separated by commas.',
            description_fa:
              'آدرس‌های سرور نام برای حل و فصل DNS را مشخص می‌کند. برای وارد کردن چندین آدرس، از کاما استفاده کنید.',
            default: '8.8.8.8,8.8.4.4',
            inputType: 'text',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'dns',
        label_fa: null,
        details: 'Tests DNS security against a predefined checklist',
        details_fa: 'امنیت DNS را بر اساس یک فهرست بررسی از پیش تعیین شده آزمایش می‌کند.',
        long_description:
          'DNS is a tool designed to test and validate DNS resolution, query response times, and the availability of name servers. It helps users analyze DNS performance, detect misconfigurations, and troubleshoot domain resolution issues.',
        long_description_fa:
          'کامپوننت DNS رکوردهای دامنه شامل A، MX و CNAME را تحلیل می‌کند. این ابزار برای شناسایی مشکلات امنیتی و بهینه‌سازی تنظیمات دامنه کاربرد دارد. هدف آن تقویت امنیت زیرساخت DNS است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMR8AVKMX3KB5SYRRVD0',
      position: {
        x: -87.5,
        y: 310.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.820945941404374',
      icon: 'http://stg-api.securest.ir/media/initialstart_e75d926d-8a78-4de5-b66d-f900f5a06a34.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'initial',
        label_fa: null,
        details:
          'The first node in every DAG, responsible for setting configurations and necessary variables',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
            {
              id: '01JJ6RTMPT464SWXE0XTHWHRXX',
              label: 'ip',
              color: '#ff0000',
            },
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
      position: {
        x: -232,
        y: 228.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.7039105633799267',
      icon: null,
      abstract_config: {
        fields: [
          {
            name: 'scanner',
            type: 'string',
            required: true,
            label: 'Scanner',
            label_fa: 'اسکنر',
            description:
              'Defines the tool used for asset discovery. Users must choose between Amass or Subfinder for scanning.',
            description_fa:
              'ابزاری که برای کشف دارایی‌ها استفاده می‌شود را مشخص می‌کند. کاربران باید بین Amass یا Subfinder برای اسکن انتخاب کنند.',
            default: 'amass',
            inputType: 'select',
            options: [
              {
                label: 'amass',
                value: 'amass',
              },
              {
                label: 'subfinder',
                value: 'subfinder',
              },
            ],
          },
          {
            name: 'threads',
            type: 'number',
            required: true,
            label: 'Threads',
            label_fa: 'تعداد نخ‌ها',
            description:
              'Sets the number of concurrent threads for scanning. A higher number speeds up the process but consumes more resources.',
            description_fa:
              'تعداد نخ‌های همزمان برای اسکن را تنظیم می‌کند. تعداد بالاتر فرآیند را سریع‌تر می‌کند ولی منابع بیشتری مصرف می‌کند.',
            default: 10,
            inputType: 'number',
            min: 1,
            max: 100,
          },
          {
            name: 'dnsbruteforce',
            type: 'boolean',
            required: false,
            label: 'DNS Brute Force',
            label_fa: 'حمله براوت‌فورس DNS',
            description:
              'If enabled, performs a brute-force attack on DNS to discover hidden subdomains. If disabled, it skips this step.',
            description_fa:
              'اگر فعال باشد، حمله براوت‌فورس به DNS انجام می‌دهد تا زیر دامنه‌های مخفی کشف شوند. اگر غیرفعال باشد، این مرحله رد می‌شود.',
            default: false,
            inputType: 'checkbox',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'assets',
        label_fa: null,
        details: 'Identifies subdomains associated with a host',
        details_fa: 'زیردامنه‌های مرتبط با یک میزبان را شناسایی می‌کند.',
        long_description:
          'The Asset Discovery component identifies and catalogs digital assets, including domains, subdomains, IPs, and services. It helps map the attack surface and provides a foundational inventory for security assessments. Essential for ensuring no digital asset is overlooked in the protection strategy.',
        long_description_fa:
          'کامپوننت Asset Discovery دارایی‌های دیجیتال مثل دامنه‌ها، زیر دامنه‌ها و آی‌پی‌ها را شناسایی می‌کند. این ابزار نقشه‌ای جامع از سطح حمله ارائه می‌دهد. هدف آن تضمین عدم نادیده‌گیری هیچ دارایی دیجیتال است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQTW5FSJMS18NKQ1H67',
      position: {
        x: -88,
        y: 231,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
  ],
  connectors: [
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.6551484174591504',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.655148417459150401JJ6RTMPZMHY8S0Y20185A65G',
    },
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.7039105633799267',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.703910563379926701JJ6RTMPZMHY8S0Y20185A65G',
    },
  ],
}
const d4 = {
  nodes: [
    {
      id: '0.13090256231343256',
      icon: 'http://stg-api.securest.ir/media/BurpSuiteburp-removebg-preview_cf0c2877-ee6a-425b-ad3d-55ebec0e7e80.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'BurpSuite',
        label_fa: null,
        details: '',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
          source: [
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JMEQW19WWR4AC3EMCD9ZZEVB',
      position: {
        x: -87,
        y: 147,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.6551484174591504',
      icon: 'http://stg-api.securest.ir/media/dnsdns-removebg-preview_a8040e05-626f-41cf-aa60-202c78d69cc6.png',
      abstract_config: {
        fields: [
          {
            name: 'NameServers',
            type: 'string',
            required: false,
            label: 'Name Servers',
            label_fa: 'سرورهای نام',
            description:
              'Specifies the name server addresses to be used for DNS resolution. Multiple addresses should be separated by commas.',
            description_fa:
              'آدرس‌های سرور نام برای حل و فصل DNS را مشخص می‌کند. برای وارد کردن چندین آدرس، از کاما استفاده کنید.',
            default: '8.8.8.8,8.8.4.4',
            inputType: 'text',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'dns',
        label_fa: null,
        details: 'Tests DNS security against a predefined checklist',
        details_fa: 'امنیت DNS را بر اساس یک فهرست بررسی از پیش تعیین شده آزمایش می‌کند.',
        long_description:
          'DNS is a tool designed to test and validate DNS resolution, query response times, and the availability of name servers. It helps users analyze DNS performance, detect misconfigurations, and troubleshoot domain resolution issues.',
        long_description_fa:
          'کامپوننت DNS رکوردهای دامنه شامل A، MX و CNAME را تحلیل می‌کند. این ابزار برای شناسایی مشکلات امنیتی و بهینه‌سازی تنظیمات دامنه کاربرد دارد. هدف آن تقویت امنیت زیرساخت DNS است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMR8AVKMX3KB5SYRRVD0',
      position: {
        x: -87.5,
        y: 310.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.820945941404374',
      icon: 'http://stg-api.securest.ir/media/initialstart_e75d926d-8a78-4de5-b66d-f900f5a06a34.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'initial',
        label_fa: null,
        details:
          'The first node in every DAG, responsible for setting configurations and necessary variables',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
            {
              id: '01JJ6RTMPT464SWXE0XTHWHRXX',
              label: 'ip',
              color: '#ff0000',
            },
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
      position: {
        x: -232,
        y: 228.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.7039105633799267',
      icon: null,
      abstract_config: {
        fields: [
          {
            name: 'scanner',
            type: 'string',
            required: true,
            label: 'Scanner',
            label_fa: 'اسکنر',
            description:
              'Defines the tool used for asset discovery. Users must choose between Amass or Subfinder for scanning.',
            description_fa:
              'ابزاری که برای کشف دارایی‌ها استفاده می‌شود را مشخص می‌کند. کاربران باید بین Amass یا Subfinder برای اسکن انتخاب کنند.',
            default: 'amass',
            inputType: 'select',
            options: [
              {
                label: 'amass',
                value: 'amass',
              },
              {
                label: 'subfinder',
                value: 'subfinder',
              },
            ],
          },
          {
            name: 'threads',
            type: 'number',
            required: true,
            label: 'Threads',
            label_fa: 'تعداد نخ‌ها',
            description:
              'Sets the number of concurrent threads for scanning. A higher number speeds up the process but consumes more resources.',
            description_fa:
              'تعداد نخ‌های همزمان برای اسکن را تنظیم می‌کند. تعداد بالاتر فرآیند را سریع‌تر می‌کند ولی منابع بیشتری مصرف می‌کند.',
            default: 10,
            inputType: 'number',
            min: 1,
            max: 100,
          },
          {
            name: 'dnsbruteforce',
            type: 'boolean',
            required: false,
            label: 'DNS Brute Force',
            label_fa: 'حمله براوت‌فورس DNS',
            description:
              'If enabled, performs a brute-force attack on DNS to discover hidden subdomains. If disabled, it skips this step.',
            description_fa:
              'اگر فعال باشد، حمله براوت‌فورس به DNS انجام می‌دهد تا زیر دامنه‌های مخفی کشف شوند. اگر غیرفعال باشد، این مرحله رد می‌شود.',
            default: false,
            inputType: 'checkbox',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'assets',
        label_fa: null,
        details: 'Identifies subdomains associated with a host',
        details_fa: 'زیردامنه‌های مرتبط با یک میزبان را شناسایی می‌کند.',
        long_description:
          'The Asset Discovery component identifies and catalogs digital assets, including domains, subdomains, IPs, and services. It helps map the attack surface and provides a foundational inventory for security assessments. Essential for ensuring no digital asset is overlooked in the protection strategy.',
        long_description_fa:
          'کامپوننت Asset Discovery دارایی‌های دیجیتال مثل دامنه‌ها، زیر دامنه‌ها و آی‌پی‌ها را شناسایی می‌کند. این ابزار نقشه‌ای جامع از سطح حمله ارائه می‌دهد. هدف آن تضمین عدم نادیده‌گیری هیچ دارایی دیجیتال است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQTW5FSJMS18NKQ1H67',
      position: {
        x: -88,
        y: 231,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
  ],
  connectors: [
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      target: '0.13090256231343256',
      targetHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      id: 'xy-edge__0.82094594140437401JJ6RTMQ2C11KR43PGPVH8QCF-0.1309025623134325601JJ6RTMQ2C11KR43PGPVH8QCF',
    },
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.6551484174591504',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.655148417459150401JJ6RTMPZMHY8S0Y20185A65G',
    },
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.7039105633799267',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.703910563379926701JJ6RTMPZMHY8S0Y20185A65G',
    },
  ],
}
const d5 = {
  nodes: [
    {
      id: '0.13090256231343256',
      icon: 'http://stg-api.securest.ir/media/BurpSuiteburp-removebg-preview_cf0c2877-ee6a-425b-ad3d-55ebec0e7e80.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'BurpSuite',
        label_fa: null,
        details: '',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
          source: [
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JMEQW19WWR4AC3EMCD9ZZEVB',
      position: {
        x: -87,
        y: 147,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.6551484174591504',
      icon: 'http://stg-api.securest.ir/media/dnsdns-removebg-preview_a8040e05-626f-41cf-aa60-202c78d69cc6.png',
      abstract_config: {
        fields: [
          {
            name: 'NameServers',
            type: 'string',
            required: false,
            label: 'Name Servers',
            label_fa: 'سرورهای نام',
            description:
              'Specifies the name server addresses to be used for DNS resolution. Multiple addresses should be separated by commas.',
            description_fa:
              'آدرس‌های سرور نام برای حل و فصل DNS را مشخص می‌کند. برای وارد کردن چندین آدرس، از کاما استفاده کنید.',
            default: '8.8.8.8,8.8.4.4',
            inputType: 'text',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'dns',
        label_fa: null,
        details: 'Tests DNS security against a predefined checklist',
        details_fa: 'امنیت DNS را بر اساس یک فهرست بررسی از پیش تعیین شده آزمایش می‌کند.',
        long_description:
          'DNS is a tool designed to test and validate DNS resolution, query response times, and the availability of name servers. It helps users analyze DNS performance, detect misconfigurations, and troubleshoot domain resolution issues.',
        long_description_fa:
          'کامپوننت DNS رکوردهای دامنه شامل A، MX و CNAME را تحلیل می‌کند. این ابزار برای شناسایی مشکلات امنیتی و بهینه‌سازی تنظیمات دامنه کاربرد دارد. هدف آن تقویت امنیت زیرساخت DNS است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMR8AVKMX3KB5SYRRVD0',
      position: {
        x: -87.5,
        y: 310.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.820945941404374',
      icon: 'http://stg-api.securest.ir/media/initialstart_e75d926d-8a78-4de5-b66d-f900f5a06a34.png',
      abstract_config: null,
      ai_assisted: false,
      data: {
        label: 'initial',
        label_fa: null,
        details:
          'The first node in every DAG, responsible for setting configurations and necessary variables',
        details_fa: '',
        long_description: '',
        long_description_fa: '',
        ports: {
          target: [],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
            {
              id: '01JJ6RTMPT464SWXE0XTHWHRXX',
              label: 'ip',
              color: '#ff0000',
            },
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
      position: {
        x: -232,
        y: 228.5,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.7039105633799267',
      icon: null,
      abstract_config: {
        fields: [
          {
            name: 'scanner',
            type: 'string',
            required: true,
            label: 'Scanner',
            label_fa: 'اسکنر',
            description:
              'Defines the tool used for asset discovery. Users must choose between Amass or Subfinder for scanning.',
            description_fa:
              'ابزاری که برای کشف دارایی‌ها استفاده می‌شود را مشخص می‌کند. کاربران باید بین Amass یا Subfinder برای اسکن انتخاب کنند.',
            default: 'amass',
            inputType: 'select',
            options: [
              {
                label: 'amass',
                value: 'amass',
              },
              {
                label: 'subfinder',
                value: 'subfinder',
              },
            ],
          },
          {
            name: 'threads',
            type: 'number',
            required: true,
            label: 'Threads',
            label_fa: 'تعداد نخ‌ها',
            description:
              'Sets the number of concurrent threads for scanning. A higher number speeds up the process but consumes more resources.',
            description_fa:
              'تعداد نخ‌های همزمان برای اسکن را تنظیم می‌کند. تعداد بالاتر فرآیند را سریع‌تر می‌کند ولی منابع بیشتری مصرف می‌کند.',
            default: 10,
            inputType: 'number',
            min: 1,
            max: 100,
          },
          {
            name: 'dnsbruteforce',
            type: 'boolean',
            required: false,
            label: 'DNS Brute Force',
            label_fa: 'حمله براوت‌فورس DNS',
            description:
              'If enabled, performs a brute-force attack on DNS to discover hidden subdomains. If disabled, it skips this step.',
            description_fa:
              'اگر فعال باشد، حمله براوت‌فورس به DNS انجام می‌دهد تا زیر دامنه‌های مخفی کشف شوند. اگر غیرفعال باشد، این مرحله رد می‌شود.',
            default: false,
            inputType: 'checkbox',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'assets',
        label_fa: null,
        details: 'Identifies subdomains associated with a host',
        details_fa: 'زیردامنه‌های مرتبط با یک میزبان را شناسایی می‌کند.',
        long_description:
          'The Asset Discovery component identifies and catalogs digital assets, including domains, subdomains, IPs, and services. It helps map the attack surface and provides a foundational inventory for security assessments. Essential for ensuring no digital asset is overlooked in the protection strategy.',
        long_description_fa:
          'کامپوننت Asset Discovery دارایی‌های دیجیتال مثل دامنه‌ها، زیر دامنه‌ها و آی‌پی‌ها را شناسایی می‌کند. این ابزار نقشه‌ای جامع از سطح حمله ارائه می‌دهد. هدف آن تضمین عدم نادیده‌گیری هیچ دارایی دیجیتال است.',
        ports: {
          target: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
          source: [
            {
              id: '01JJ6RTMPZMHY8S0Y20185A65G',
              label: 'domain',
              color: '#00ff00',
            },
          ],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMQTW5FSJMS18NKQ1H67',
      position: {
        x: -88,
        y: 231,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: false,
      dragging: false,
    },
    {
      id: '0.7538294302637494',
      icon: 'http://stg-api.securest.ir/media/acunetixacunetix-logo-featured-image-removebg-preview_ff1d6ae9-e0d3-4340-ab9a-3ed6519eadcd.png',
      abstract_config: {
        fields: [
          {
            name: 'speed',
            type: 'number',
            required: true,
            label: 'Scan Speed',
            label_fa: 'سرعت اسکن',
            description:
              'Determines the scan speed, with 1 being the slowest and 3 being the fastest.',
            description_fa: 'سرعت اسکن را تعیین می‌کند، ۱ کندترین و ۳ سریع‌ترین است.',
            default: null,
            inputType: 'number',
            min: 1,
            max: 3,
          },
          {
            name: 'header',
            type: 'string',
            required: false,
            label: 'Headers',
            label_fa: 'هدرها',
            description: 'Allows custom headers to be included in HTTP requests during scanning.',
            description_fa:
              'امکان اضافه کردن هدرهای سفارشی به درخواست‌های HTTP در طول اسکن را فراهم می‌کند.',
            default: null,
            inputType: 'text',
          },
          {
            name: 'profile',
            type: 'string',
            required: true,
            label: 'Scan Profile',
            label_fa: 'پروفایل اسکن',
            description:
              'Defines the type of security scan, with options such as OWASP TOP 10, SQL Injection, XSS, Critical Only, Sans Top 25, and Full Scan.',
            description_fa:
              'نوع اسکن امنیتی را تعریف می‌کند، مانند OWASP TOP 10، تزریق SQL، XSS، فقط موارد بحرانی، Sans Top 25 و اسکن کامل.',
            default: null,
            inputType: 'select',
            options: [
              {
                label: 'OWASP TOP 10',
                value: 'OWASP TOP 10',
              },
              {
                label: 'SQL Injection',
                value: 'SQL Injection',
              },
              {
                label: 'XSS',
                value: 'XSS',
              },
              {
                label: 'Critical Only',
                value: 'Critical Only',
              },
              {
                label: 'Sans Top 25',
                value: 'Sans Top 25',
              },
              {
                label: 'Full Scan',
                value: 'Full Scan',
              },
            ],
          },
          {
            name: 'HTTPBasic',
            type: 'string',
            required: false,
            label: 'HTTP Basic Authentication',
            label_fa: 'احراز هویت HTTP Basic',
            description:
              'Specifies HTTP Basic Authentication credentials in the format username:password for scanning protected pages.',
            description_fa:
              'اطلاعات احراز هویت HTTP Basic را در قالب نام‌کاربری:رمزعبور برای اسکن صفحات محافظت‌شده مشخص می‌کند.',
            default: null,
            inputType: 'text',
          },
          {
            name: 'CaseSensitivePath',
            type: 'boolean',
            required: false,
            label: 'Case Sensitive Paths',
            label_fa: 'مسیرهای حساس به حروف بزرگ و کوچک',
            description:
              'If enabled, treats URLs with different cases as separate paths, which is useful for case-sensitive web servers.',
            description_fa:
              'در صورت فعال بودن، مسیرهایی با حروف کوچک و بزرگ متفاوت را به عنوان مسیرهای جداگانه در نظر می‌گیرد، که برای سرورهای وب حساس به حروف بزرگ و کوچک مفید است.',
            default: null,
            inputType: 'checkbox',
          },
        ],
      },
      ai_assisted: true,
      data: {
        label: 'acunetix',
        label_fa: null,
        details: 'A vulnerability scanner for all type of sites',
        details_fa: '',
        long_description:
          'Acunetix is an automated web application security scanner that detects vulnerabilities such as SQL injection, XSS, and misconfigurations. It performs comprehensive scans to identify security risks in web applications.\r\n\r\nSpeed, Determines the scan speed, with 1 being the slowest and 3 being the fastest.\r\n\r\nHeader, Allows custom headers to be included in HTTP requests during scanning.\r\n\r\nProfile, Defines the type of security scan, with options such as OWASP TOP 10, SQL Injection, XSS, Critical Only, Sans Top 25, and Full Scan.\r\n\r\nHTTP Basic, Specifies HTTP Basic Authentication credentials in the format username:password for scanning protected pages.\r\n\r\nCase Sensitive Path, If enabled, treats URLs with different cases as separate paths, which is useful for case-sensitive web servers.',
        long_description_fa: '',
        ports: {
          target: [
            {
              id: '01JJ6RTMQ2C11KR43PGPVH8QCF',
              label: 'link',
              color: '#0000ff',
            },
          ],
          source: [],
        },
      },
      type: 'default',
      node_type_id: '01JJ6RTMT2N2NJVT1TEK2XPD1D',
      position: {
        x: 82,
        y: 218,
      },
      config: {},
      measured: {
        width: 60,
        height: 60,
      },
      selected: true,
      dragging: false,
    },
  ],
  connectors: [
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      target: '0.13090256231343256',
      targetHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      id: 'xy-edge__0.82094594140437401JJ6RTMQ2C11KR43PGPVH8QCF-0.1309025623134325601JJ6RTMQ2C11KR43PGPVH8QCF',
    },
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.6551484174591504',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.655148417459150401JJ6RTMPZMHY8S0Y20185A65G',
    },
    {
      source: '0.820945941404374',
      sourceHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      target: '0.7039105633799267',
      targetHandle: '01JJ6RTMPZMHY8S0Y20185A65G',
      id: 'xy-edge__0.82094594140437401JJ6RTMPZMHY8S0Y20185A65G-0.703910563379926701JJ6RTMPZMHY8S0Y20185A65G',
    },
    {
      source: '0.13090256231343256',
      sourceHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      target: '0.7538294302637494',
      targetHandle: '01JJ6RTMQ2C11KR43PGPVH8QCF',
      id: 'xy-edge__0.1309025623134325601JJ6RTMQ2C11KR43PGPVH8QCF-0.753829430263749401JJ6RTMQ2C11KR43PGPVH8QCF',
    },
  ],
}
// ///////////////////////////////////////////////////
export const text = [
  'Thinking',
  'Mapping the workflow',
  'Structuring the data flow',
  'Addressing critical considerations'

]
// ///////////////////////////////////////////////////
export const data = [
  {
    "id": "01JJ6RTMQ9Y0JZTSKKS2AAHC1N",
    "position": { "x": 0, "y": 0 },
    "icon": "01JMF3NVQV0C6MA5STGM4W7QBZ",
    "config": {},
    "abstract_config": {},
    "node_type_id": "01JJ6RTMQ9Y0JZTSKKS2AAHC1N",
    "ai_assisted": false,
    "ai_example": "",
    "ai_example_fa": "",
    "data": {
      "label": "initial",
      "details": "The first node in every DAG, responsible for setting configurations and necessary variables",
      "ports": {
        "target": [],
        "source": [{ "id": "out", "label": "source", "color": "green" }]
      }
    }
  },
  {
    "id": "01JJ6RTMQTW5FSJMS18NKQ1H67",
    "position": { "x": 300, "y": 0 },
    "icon": null,
    "config": {
      "scanner": "amass",
      "threads": 10,
      "dnsbruteforce": false
    },
    "abstract_config": {
      "scanner": {
        "type": "enum",
        "choices": ["amass", "subfinder"],
        "default": "amass",
        "required": true
      },
      "threads": {
        "type": "integer",
        "default": 10,
        "required": true
      },
      "dnsbruteforce": {
        "type": "boolean",
        "default": false
      }
    },
    "node_type_id": "01JJ6RTMQTW5FSJMS18NKQ1H67",
    "ai_assisted": true,
    "ai_example": "",
    "ai_example_fa": "",
    "data": {
      "label": "assets",
      "details": "Identifies subdomains associated with a host",
      "ports": {
        "target": [{ "id": "in", "label": "target", "color": "blue" }],
        "source": [{ "id": "out", "label": "source", "color": "green" }]
      }
    }
  },
  {
    "id": "01JJ6RTMXG5M69QTQ67V8FQ9W0",
    "position": { "x": 600, "y": 0 },
    "icon": "01JMER3R7HY0BJY4HMKTCVRQ25",
    "config": {
      "crawl": true,
      "delay": 2,
      "level": 3,
      "speed": 3
    },
    "abstract_config": {
      "crawl": { "type": "boolean" },
      "delay": { "type": "integer" },
      "level": { "type": "integer" },
      "speed": { "type": "integer" }
    },
    "node_type_id": "01JJ6RTMXG5M69QTQ67V8FQ9W0",
    "ai_assisted": true,
    "ai_example": "",
    "ai_example_fa": "",
    "data": {
      "label": "waymore",
      "details": "Searches online databases for website archives to identify paths",
      "ports": {
        "target": [{ "id": "in", "label": "target", "color": "blue" }],
        "source": [{ "id": "out", "label": "source", "color": "green" }]
      }
    }
  },
  {
    "id": "01JJ6RTMX6BMBX1J3V5B0K1ARK",
    "position": { "x": 900, "y": 0 },
    "icon": "01JMERNS1E1WABNEVXFC8D0C6K",
    "config": {
      "certInfo": true,
      "checkAll": true,
      "protocols": true
    },
    "abstract_config": {
      "certInfo": { "type": "boolean" },
      "checkAll": { "type": "boolean" },
      "protocols": { "type": "boolean" }
    },
    "node_type_id": "01JJ6RTMX6BMBX1J3V5B0K1ARK",
    "ai_assisted": true,
    "ai_example": "",
    "ai_example_fa": "",
    "data": {
      "label": "ssl",
      "details": "Tests SSL security using a checklist",
      "ports": {
        "target": [{ "id": "in", "label": "target", "color": "blue" }],
        "source": [{ "id": "out", "label": "source", "color": "green" }]
      }
    }
  },
  {
    "id": "01JJ6RTMTF02KC8PMBP50GCXTG",
    "position": { "x": 1200, "y": 0 },
    "icon": "01JMEPW6CPG05DQKZP8S4MFDSS",
    "config": {
      "profile": "Web Application Tests",
      "threads": 20,
      "HTTPBasic": ""
    },
    "abstract_config": {
      "profile": {
        "type": "enum",
        "choices": ["Web Application Tests", "Basic Network Scan", "Advanced Scan",
          "Database Security Scan", "Network Scan", "Full Scan"]
      },
      "threads": { "type": "integer" },
      "HTTPBasic": { "type": "string" }
    },
    "node_type_id": "01JJ6RTMTF02KC8PMBP50GCXTG",
    "ai_assisted": true,
    "ai_example": "",
    "ai_example_fa": "",
    "data": {
      "label": "nessus",
      "details": "A vulnerability scanner for all type of sites",
      "ports": {
        "target": [{ "id": "in", "label": "target", "color": "blue" }],
        "source": []
      }
    }
  }
]

export const nodeTypeID = [
  '01JJ6RTMQ9Y0JZTSKKS2AAHC1N',
  "01JMVG7TKFBNABT1J7JPNZDPF5",
  "01JMVG8T029X00KE38WTR6FBZR",
  "01JMVG9G0K9J6H7MCJA7YCG8BG",
  "01JMVGF0JJERG0PZV4TZ2D5V1Z",
  "01JJ6RTMTF02KC8PMBP50GCXTG",
  "01JJ6RTMT2N2NJVT1TEK2XPD1D",
  "01JMEQW19WWR4AC3EMCD9ZZEVB",
  "01JJ6RTMSQJG8JQ0PAEGF6GD99",
  "01JJ6RTMWS6DP4A4VD5TXP4QPQ",
  "01JMMVRAG0Q8M4XAQ1NV0ZFPVP",
]