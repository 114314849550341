import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useIntl } from 'react-intl';
import InfoIcon from '../../images/InfoIcon';

interface ChartDataItem {
  keyword: string;
  clicks: number;
}

interface ChartProps {
  header: string;
  headerRowTitle1: string;
  headerRowTitle2: string;
  headerRowColor: string;
  bodyRowsTitleColor: string;
  bodyRowsDataColor: string;
  chartData: ChartDataItem[];
}

interface BarChartAssetProps {
  data: ChartProps;
  loading: boolean;
}

const BarChartAsset: React.FC<BarChartAssetProps> = ({ data, loading }) => {
  const intl = useIntl();
  const maxChartWidth = Math.max(...data.chartData?.map(item => item.clicks));

  // Configuration for severity levels and their icons/colors
  const severityConfig: Record<string, { icon: string; colorClass: string }> = {
    'Sensetive Files': { 
      icon: 'fas fa-file-shield', 
      colorClass: 'danger' 
    },
    'XSS': { 
      icon: 'fas fa-bug', 
      colorClass: 'warning' 
    },
    'Open Ports': { 
      icon: 'fas fa-ethernet', 
      colorClass: 'info' 
    }
  };

  return (
    <div className={`card h-100  ${loading ? 'skeleton' : ''}`}>
      {/* Chart Header */}
      <div className="d-flex justify-content-between align-items-center border-0 bg-secondary p-4 rounded-top h-50px ps-5">
        <div className="d-flex align-items-center">
          <span className="mb-0 fw-bold fs-6">
            {intl.formatMessage({ id: data.header })}
          </span>
          <span 
            data-tooltip-id={`tooltip-${data.header}`}
            className="d-flex align-items-center"
          >
            <InfoIcon />
          </span>
        </div>
        <span className="bg-primary-subtle px-3 py-2 text-primary badge">
          <i className="fa-shield-check fas me-1"></i>
          24h
        </span>
      </div>

      {/* Chart Content */}
      <div className="p-4 pb-0 card-body" style={{filter:'blur(0px)'}}>
        {/* Header Row */}
        <div className="mb-4 row" style={{ color: data.headerRowColor }}>
          <div className="col-7">
            <h4 className="mb-0 fw-bold fs-6">
              {intl.formatMessage({ id: data.headerRowTitle1 })}
            </h4>
          </div>
          <div className="col-5">
            <h4 className="mb-0 fw-bold fs-6">
              {intl.formatMessage({ id: data.headerRowTitle2 })}
            </h4>
          </div>
        </div>

        {/* Chart Items */}
        {data.chartData.map((item, index) => (
          <div 
            key={item.keyword}
            className="align-items-center py-3 row"
            style={index !== data.chartData.length - 1 ? {
              borderBottom: '1px dashed',
              borderColor: data.headerRowColor
            } : {}}
          >
            {/* Item Label */}
            <div className="d-flex align-items-center gap-3 col-7">
              <div className={`d-flex align-items-center justify-content-center p-2 rounded bg-${severityConfig[item.keyword]?.colorClass}-subtle`}>
                <i className={`${severityConfig[item.keyword]?.icon} text-${severityConfig[item.keyword]?.colorClass}`}></i>
              </div>
              <span 
                className="fw-semibold" 
                style={{ color: data.bodyRowsTitleColor }}
              >
                {intl.formatMessage({ id: item.keyword })}
              </span>
            </div>

            {/* Progress Bar */}
            <div className="px-5 col-5">
              <div className="position-relative">
                <div 
                  className="bg-gray-200 progress"
                  style={{ height: '12px' }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${(item.clicks / maxChartWidth) * 100}%`,
                      backgroundColor: data.bodyRowsDataColor,
                      borderRadius: '0 4px 4px 0'
                    }}
                    aria-valuenow={item.clicks}
                    aria-valuemin={0}
                    aria-valuemax={maxChartWidth}
                  >
                    <span className="position-absolute text-gray-900 translate-middle-y end-0 number_progress start-100">
                      {item.clicks}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Tooltip 
        id={`tooltip-${data.header}`}
        className="bg-dark mw-200px text-white fs-7"
      >
        {intl.formatMessage({ id: 'Top Vulnerabilities tooltip' })}
      </Tooltip>
    </div>
  );
};

export default BarChartAsset;