import {FC, Suspense, lazy, useEffect} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import Dashboard1 from '../pages/dashboard1/Dashboard1'
import {Vulnerabilities} from '../pages/vulnerabilities'
import VulnerabilityDetails from '../pages/vulnerabilities/components/VulnerabilityDetails'
import AssetDetails from '../pages/exposures/Domains/details/AssetDetails'
import {Waf} from '../pages/waf'
import {DNSInspect} from '../pages/dnsInspect'
import {SMTP} from '../pages/smtp'
import {WorkFlow} from '../pages/workFlow'
// import DAG from '../pages/workFlow/components/DAG'
import SSLDetails from '../pages/exposures/SSL/details/SSLDetails'
import {Error404} from '../modules/errors/components/Error404'
import {Scan} from '../pages/scan'
import ScanDetails from '../pages/scan/components/ScanDetails'
// import CreateDAG from '../pages/workFlow/components/CreateDAG'
import AttackSurface from '../pages/exposures'
import Explore from '../pages/exposures/Explore'
import Domains from '../pages/exposures/Domains/Domains'
import Ports from '../pages/exposures/Ports/Ports'
import DNS from '../pages/exposures/DNS/DNS'
import IPs from '../pages/exposures/IPs/IPs'
import Email from '../pages/exposures/Email/Email'
import Leaked from '../pages/exposures/Leaked/Leaked'
import {SSLExposures} from '../pages/exposures/SSL/SSL'
import PricePlan from '../pages/price_plan/PricePlan'
import Services from '../pages/services/Services'
import App from '../pages/exposures/App/App'
import Tech from '../pages/exposures/Tech/Tech'
import Verify from '../pages/price_plan/Verify'
import {SSL} from '../pages/ssl'
import {Target} from '../pages/target'
import {Asset} from '../pages/asset'
import Integrations from '../pages/integrations/Index'
import Ticket from '../pages/ticket/Index'
import History from '../pages/price_plan/History'
import TicketDetails from '../pages/ticket/TicketDetails'
import AiTemplate from '../pages/ai_template'
import CreateScan from '../pages/scan/components/CreateScan'
import VerifyFree from '../pages/price_plan/VerifyFree'
import CreateDAG from '../pages/workFlow/components/CreateDAG'
import Invoices from '../pages/price_plan/Invoices'
import AppDetails from '../pages/exposures/App/Details'
import NodeDetails from '../pages/workFlow/components/NodeDetails'
import Schedule from '../pages/scan/components/schedule/Schedule'
import { useSelector } from 'react-redux'
import { RootState } from '../../setup/redux/Store'

/**
 * PrivateRoutes component for handling authenticated routes
 * @returns {JSX.Element} The rendered routes
 */
const PrivateRoutes = () => {
  // Lazy-loaded components
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const Dos = lazy(() => import('../pages/dos'))
  const user = useSelector((state: RootState) => state.root.user)
  const navigate = useNavigate()
useEffect(()=>{
  if (user?.is_user_new) {
    navigate('/price-plan')
    console.log('user', user.is_user_new)
  }
},[]) 
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect /auth to /dashboard */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Attack Surface routes */}
        <Route path='/attack-surface' element={<AttackSurface />} />
        <Route path='/attack-surface-explore' element={<Explore />}>
          {/* Nested routes for different attack surface components */}
          <Route path='Domains' element={<Domains />} />
          <Route path='Applications' element={<App />} />
          <Route path='Applications/details' element={<AppDetails />} />
          <Route path='IP' element={<IPs />} />
          <Route path='DNS Records' element={<DNS />} />
          <Route path='Ports' element={<Ports />} />
          <Route path='SSL' element={<SSLExposures />} />
          <Route path='Email Address' element={<Email />} />
          <Route path='Technologies' element={<Tech />} />
          <Route path='Leaked Data' element={<Leaked />} />
        </Route>

        {/* {workFlow} */}
        <Route path='/workFlow' element={<WorkFlow />} />
        <Route
          path='/workFlow-edit'
          element={
            <Suspense fallback={<div className={'w-100 h-100 skeleton'}></div>}>
              <CreateDAG />
            </Suspense>
          }
        />
        <Route path='/workFlow-create' element={<CreateDAG />} />
        <Route path='//workflow/node-types' element={<NodeDetails />} />
        {/* {workFlow} */}
        {/* {vulnerabilities} */}
        <Route path='/vulnerabilities' element={<Vulnerabilities />} />
        <Route path='/vulnerability-details' element={<VulnerabilityDetails />} />

        {/* {assets} */}
        <Route path='/assets' element={<Asset />} />
        <Route path='/asset-details' element={<AssetDetails />} />

        {/* {scan} */}
        <Route path='/scan-history' element={<Scan />} />
        <Route path='/scan-details' element={<ScanDetails />} />
        <Route path='/scan-create' element={<CreateScan />} />
        <Route path='/scan-schedule' element={<Schedule/>} />

        {/* {ssl} */}
        <Route path='/Toolbox/ssl' element={<SSL />} />
        <Route path='/Toolbox/ssl-details' element={<SSLDetails />} />

        <Route path='/dashboard' element={<Dashboard1 />} />
        <Route path='/price-plan' element={<PricePlan />} />
        <Route path='/payment/verify/*' element={<Verify />} />
        <Route path='/payment/verify_success/*' element={<VerifyFree />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/target' element={<Target />} />
        <Route path='/Toolbox/waf' element={<Waf />} />
        <Route path='/Toolbox/dns' element={<DNSInspect />} />
        <Route path='/Toolbox/smtp' element={<SMTP />} />
        <Route
          path='/Toolbox/dos'
          element={
            <SuspensedView>
              <Dos />
            </SuspensedView>
          }
        />
        <Route path='/integrations' element={<Integrations />} />
        <Route path='/ticket' element={<Ticket />} />
        <Route path='/ticket-details' element={<TicketDetails />} />
        <Route path='/payment-history' element={<History />} />
        <Route path='/Invoices' element={<Invoices />} />
        <Route path='/Toolbox/AI-Template' element={<AiTemplate />} />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Error404 />} />
        {/* <Route path='*' element={<Navigate to='404' />} /> */}
      </Route>
    </Routes>
  )
}
/*
 * Props for SuspensedView component
 * @typedef {Object} SuspensedViewProps
 * @property {React.ReactNode} children - The child components to render
 *
/*
 * SuspensedView component for handling lazy-loaded components with a loading indicator
 * @param {SuspensedViewProps} props - The component props
 * @returns {JSX.Element} The rendered component
 */
interface SuspensedViewProps {
  children: React.ReactNode
}
const SuspensedView: FC<SuspensedViewProps> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')

  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
