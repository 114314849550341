import axios from 'axios'
import React, {useLayoutEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import usePermission from '../../../../Utils/usePermission'
import {NodeDetailsType} from '../../../modules/types/workflow'
import {Badge} from 'react-bootstrap'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import { useIntl } from 'react-intl'
const findInitialValue = (item: {[key: string]: any}) => {
  switch (item.type) {
    case 'boolean':
      return {
        color: 'primary',
        value: 'false',
      }
    case 'number':
      return {
        color: 'warning',
        value: item.min || 'NaN',
      }
    case 'string':
      return {
        color: 'info',
        value: 'mpt string',
      }
    case 'array':
      return {
        color: 'secondary',
        value: 'mpt list',
      }
    default:
      return {
        color: 'danger',
        value: '',
      }
  }
}
const DEFAULT_HANDLE_STYLE = {
  width: 20,
  height: 20,
  bottom: -5,
  fontSize: 8,
  borderRedus: '100%',
}
function findPosition (ind: number, arr: number) {
  switch (arr) {
    case 1:
      return '50%'
    case 2:
      if (ind === 0) {
        return '33%'
      }
      if (ind === 1) {
        return `calc(33% + ${ind * 20}px)`
      }
      return '0'
    case 3:
      if (ind === 0) {
        return '50%'
      }
      if (ind === 1) {
        return `25% `
      }
      if (ind % 2 === 0) {
        return `75%`
      }
      return `calc(50% + ${ind * 10}px)`
    case 4:
      if (ind === 0) {
        return '0%'
      }
      if (ind === 1) {
        return `33%`
      }
      if (ind === 2) {
        return `66%`
      }
      if (ind === 3) {
        return `100%`
      }
      return '0'
    case 5:
      if (ind === 0) {
        return '0%'
      }
      if (ind === 1) {
        return `25%`
      }
      if (ind === 2) {
        return `50%`
      }
      if (ind === 3) {
        return `75%`
      }
      if (ind === 4) {
        return `100%`
      }
      return '0'
    default:
      if (ind <= 5) {
        return `${20 * ind}%`
      }
      if (ind % 2 === 0) {
        return `calc(100% + ${ind * 30}px)`
      } else {
        return `calc(0% - ${ind * 30}px)`
      }
  }
}
export default function NodeDetails () {
  const lang = useLang()?.lang.selectedLang
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<NodeDetailsType | null>(null)
  const errorHandling = useErrorHandling()
  const {getPermission, isPermission} = usePermission()
  const fetchNode = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/workflow/node-types/${id}/`
      )
      errorHandling(data.status)
      getPermission(data.status)

      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(`Error fetching DNS data:`, data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    fetchNode()
  }, [id])
  console.log(data)
  return (
    <div className={`position-relative ${loading && 'skeleton'}`}>
      {isPermission()}
      <div className='p-5 card'>
        <h2>{lang === 'fa' ? data?.data.label_fa || data?.data.label : data?.data.label}</h2>
        <div className='d-flex my-5' style={{gap: '7rem'}}>
          <div className='d-flex flex-column align-items-center'>
            <p className='text-muted'>{intl.formatMessage({id: 'Category'})}</p>
            <p className=''> {lang ==='fa'? data?.group||data?.group|| '_':data?.group|| '_'}</p>
          </div>
          <div className='d-flex flex-column align-items-center'>
            <p className='text-muted'>{intl.formatMessage({id: 'Category description'})}</p>
            <p className=''> {lang ==='fa'? data?.group_description_fa||data?.group_description || '_':data?.group_description || '_'} </p>
          </div>
        </div>

        <hr />
        <div className='d-flex justify-content-between m-2'>
          <div
            className={`d-flex justify-content-center ${lang === 'fa' ? 'border-start':'border-end'}`}
            dir='ltr'
            style={{minWidth: 500, width: '30%'}}
          >
            <div
              className=' position-relative d-flex justify-content-center align-items-center'
              style={{width: '220px', height: '220px', border: '1px solid gray', borderRadius: 5}}
            >
              <div
                className='position-absolute '
                style={{
                  overflow: 'hidden',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  zIndex: 0,
                  background: 'rgba(175, 175, 175, 0.17)',
                  backdropFilter: 'blur(3px)',
                  WebkitBackdropFilter: 'blur(3px)',
                }}
              ></div>
              <div
                className=' min-w-200px min-h-200px large_item_icon  '
                style={data?.icon ? {backgroundImage: `url(${data.icon})`, zIndex: 1} : {zIndex: 1}}
              >
                {/* Container for background blur effect */}
                {data?.data.ports?.source?.map((port: any, index: number, arr: any[]) => {
                  return (
                    <div
                      className='react-flow__handle-right position-absolute rounded-circle source'
                      key={index}
                      id={port.id}
                      style={{
                        ...DEFAULT_HANDLE_STYLE,
                        top: findPosition(index, arr.length),
                        backgroundColor: port.color,
                        right: -10,
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: 5,
                          left: 22,
                          color: port.color,
                          textWrap: 'nowrap',
                          fontSize: 16,
                        }}
                      >
                        {port.label}
                      </span>
                    </div>
                  )
                })}
                {data?.data.ports?.target?.map((port: any, index: number, arr: any[]) => {
                  return (
                    <div
                      className='react-flow__handle-right position-absolute rounded-circle source'
                      key={index}
                      style={{
                        ...DEFAULT_HANDLE_STYLE,
                        top: findPosition(index, arr.length),
                        backgroundColor: port.color,
                        left: -27,
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: 5,
                          color: port.color,
                          textWrap: 'nowrap',
                          fontSize: 16,
                          left: -7,
                          transform: 'translateX(-100%)',
                        }}
                      >
                        {port.label}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <p className='p-4'>
            {lang === 'fa'
              ? data?.data.long_description_fa || data?.data.long_description
              : data?.data.long_description}
          </p>
        </div>
        {data?.abstract_config && (
          <>
            <hr />
            <div className='d-flex flex-column align-items-center gap-2 pt-5'>
              <strong className='align-self-start'>{intl.formatMessage({id: 'Configs'})}</strong>
              <div className='d-flex gap-5 w-100 mw-700px min-w-500px'>
                <strong style={{width: '30%'}} className=' text-center text-muted'>
                {intl.formatMessage({id: 'Name'})}
                  
                </strong>
                <strong style={{width: '30%'}} className=' text-center text-muted'>
                {intl.formatMessage({id: 'Type'})}
                  
                </strong>
                <strong style={{width: '30%'}} className=' text-center text-muted'>
                {intl.formatMessage({id: 'Initial value'})}
                  
                </strong>
              </div>

              {data?.abstract_config?.fields?.map((item, ind) => {
                const {value, color} = findInitialValue(item)
                return (
                  <div
                    className='d-flex gap-5 border  p-2 w-100 mw-700px min-w-500px h-auto bg-gray-200 rounded-1 align-items-center'
                    key={ind}
                  >
                    <strong style={{width: '30%'}} className=' text-center'>
                      {item.name}
                    </strong>
                    <strong style={{width: '30%'}} className=' text-center'>
                      {item.type}
                    </strong>
                    <strong
                      style={{width: '30%'}}
                      className=' text-center d-flex justify-content-center'
                    >
                      <Badge
                        bg={color}
                        className='min-w-40px d-flex justify-content-center'
                        style={{maxWidth: 'fit-content'}}
                      >
                        {value}
                      </Badge>
                    </strong>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
