import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import {OptionsType, SelectedType} from '../../modules/types/filters'

export default function Filters ({
  get,
  filterOptionDomains,
  selected,
  filterSelected,
  show,
  setShow,
}: {
  get: any
  filterOptionDomains: OptionsType
  selected: SelectedType
  filterSelected: any
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [open, setOpen] = useState<number | null>(null)

  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(filterSelected(copy))
  }
  const getSelectedCount = () => {
    if (!selected) return 0
    return Object.values(selected).reduce((acc, curr) => acc + curr.length, 0)
  }
  return (
    <div
      className={`offcanvas offcanvas-end ${show ? 'show' : ''}`}
      // tabIndex="-1"
      style={{width: '400px', visibility: show ? 'visible' : 'hidden'}}
    >
      <div className='offcanvas-header border-bottom'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <h5 className='offcanvas-title mb-0'>
          {intl.formatMessage({id: 'Filters'})}
            
            {getSelectedCount() > 0 && (
              <span className='badge bg-gray-300 text-gray-600 ms-2'>
                {getSelectedCount()}{intl.formatMessage({id: 'selected'})} 
              </span>
            )}
          </h5>
          <div  className=' text-reset' style={{cursor:'pointer'}} onClick={() => setShow(false)}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2 image_change_theme2' />
          </div>
        </div>
      </div>

      <div className='offcanvas-body'>
        <div className='d-flex justify-content-between mb-4'>
          <button
            type='button'
            className='btn btn-primary d-flex align-items-center'
            onClick={() => get({query: '?page=1'})}
          >
            {intl.formatMessage({id: 'Apply Filters'})}
            
          </button>

          <button
            type='button'
            className='btn btn-warning d-flex align-items-center'
            onClick={() => {
              dispatch(filterSelected(null))
              get({query: '?page=1', filterProps: {}})
            }}
          >
            {intl.formatMessage({id: 'Reset Filters'})}
            
          </button>
        </div>

        <div className='filter-sections' style={{height: 'calc(100vh - 180px)', overflowY: 'auto'}}>
          {filterOptionDomains ? (
            Object.keys(filterOptionDomains)?.map((items, index) => (
              <div key={index} className='card shadow-sm mb-4'>
                <div className='card-header bg-light py-3'>
                  <h6 className='card-title mb-0 text-capitalize'>
                    {intl.formatMessage({id: items})}
                  </h6>
                </div>
                <div className='card-body p-0'>
                  {filterOptionDomains[items]?.length ? (
                    <div className='list-group list-group'>
                      {filterOptionDomains[items]
                        .slice(
                          0,
                          filterOptionDomains[items]?.length > 10
                            ? open === index
                              ? filterOptionDomains[items]?.length
                              : 10
                            : filterOptionDomains[items]?.length
                        )
                        .map((item, ind) => (
                          <div
                            key={ind}
                            className='list-group-item list-group-item-action border-0 py-2 px-3'
                            onClick={() => selectedHandel(items, item.name)}
                            style={{cursor: 'pointer'}}
                          >
                            <div className='d-flex justify-content-between align-items-center'>
                              <div className='form-check mb-0' dir='ltr'>
                                <input
                                  type='checkbox'
                                  className='form-check-input form-check-input-reverse'
                                  checked={selected?.[items]?.includes(item.name)}
                                  onChange={() => {}} // Handled by parent click
                                  id={`${items}-${ind}`}
                                />
                                <label className='form-check-label' htmlFor={`${items}-${ind}`}>
                                  {item.name}
                                </label>
                              </div>
                              <span className='badge bg-gray-300 text-gray-600'>{item.count}</span>
                            </div>
                          </div>
                        ))}

                      {filterOptionDomains[items]?.length > 10 && (
                        <button
                          className='btn btn-link text-decoration-none w-100 py-2'
                          onClick={() => setOpen(open === index ? null : index)}
                        >
                          <i className={`bi bi-chevron-${open === index ? 'up' : 'down'} me-1`}></i>
                          {intl.formatMessage({
                            id: open === index ? 'show less' : 'show more',
                          })}
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className='p-3 text-muted'>N/A</div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className='text-center text-muted py-5'>No filters available</div>
          )}
        </div>
      </div>
    </div>
  )
}
