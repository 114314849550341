import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

interface IpDetailsProps {
  asn?: string
  asn_domain?: string
  asn_name?: string
  cidr?: string
  loc?: string | null
}

interface IpAddressProps {
  ip: string
  version: string
  id: string
  details: IpDetailsProps
  isFirst: boolean
}

const IpAddressSection: React.FC<IpAddressProps> = ({ip, version, id, details, isFirst}) => {
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState(isFirst)

  const detailItems = [
    {key: 'ASN', value: details.asn},
    {key: 'asn_domain', value: details.asn_domain},
    {key: 'asn_name', value: details.asn_name},
    {key: 'cidr', value: details.cidr},
    {key: 'Location', value: details.loc},
  ]

  return (
    <div className='card bg-secondary bg-opacity-15 border-0 mb-3'>
      <div
        className='card-header border-0 py-3 px-4  cursor-pointer'
        role='button'
        onClick={() => setIsExpanded(!isExpanded)}
        data-bs-toggle='collapse'
        data-bs-target={`#kt_ip_details_${id}`}
        aria-expanded={isExpanded}
        aria-controls={`kt_ip_details_${id}`}
      >
        <div className='d-flex  align-items-center justify-content-between w-100'
        
        >
          <div className='d-flex align-items-center w-100'
          style={{maxWidth:'calc(100% - 22px)'}}
          >
            <div className='symbol symbol-40px me-3'>
              <KTSVG
                path={
                  version === 'ipv4'
                    ? '/media/icons/duotune/technology/teh008.svg'
                    : '/media/icons/duotune/technology/teh009.svg'
                }
                className='svg-icon-2x image_change_theme2'
              />
            </div>
            <div className='d-flex flex-column flex-grow-1 min-w-40px mw-100'>
              <h3 className='fw-bold mb-1 fa-ellipsis-v '>{ip}</h3>
              <span className='text-muted fs-7 '>{version.toUpperCase()}</span>
            </div>
          </div>
          <span className='ms-auto'>
            <KTSVG
              path='/media/icons/duotune/arrows/arr072.svg'
              className={`svg-icon-2 image_change_theme2 ${isExpanded ? 'rotate-180' : ''}`}
            />
          </span>
        </div>
      </div>

      <div id={`kt_ip_details_${id}`} className={`collapse  ${isExpanded ? 'show' : ''}`}>
        <div className='card-body pt-3 pb-0 px-3'>
          {detailItems.map((item, index) => (
            <div key={item.key} className='d-flex align-items-center py-2'>
              <span className='bullet bullet-vertical bg-primary me-5'></span>
              <div className='d-flex justify-content-between flex-grow-1'>
                <strong className='text-gray-800'>{intl.formatMessage({id: item.key})}</strong>
                <span className='text-muted fw-semibold'>{item.value || 'N/A'}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

interface IpAddressCardProps {
  ipData?:
    | Array<{
        ip: string
        version: 'ipv4' | 'ipv6'
        id: string
        asn?: string
        asn_domain?: string
        asn_name?: string
        cidr?: string
        loc?: string | null
      }>
    | null
    | undefined
  version: 'ipv4' | 'ipv6'
}

const IpAddressCard: React.FC<IpAddressCardProps> = ({ipData = [], version}) => {
  const intl = useIntl()
  const filteredIps = ipData?.filter((ip) => ip.version === version) || []
  return (
    <div className='card bg-mh_black'>
      <div className='card-header border-0 pt-5 px-4 '>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex align-items-center'>
            <span className='card-label fw-bold'>{intl.formatMessage({id: 'IP Address'})}</span>
            <span className='badge badge-light-primary ms-3'>
              {intl.formatMessage({id: version.toUpperCase()})}
            </span>
          </div>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {version.toUpperCase()} {intl.formatMessage({id: `addresses and their details`})}
          </span>
        </h3>
      </div>

      <div className='card-body py-3 px-4'>
        {filteredIps.length > 0 ? (
          filteredIps.map((ip, index) => (
            <IpAddressSection
              key={ip.id}
              ip={ip.ip}
              version={ip.version}
              id={ip.id}
              details={{
                asn: ip.asn,
                asn_domain: ip.asn_domain,
                asn_name: ip.asn_name,
                cidr: ip.cidr,
                loc: ip.loc,
              }}
              isFirst={index === 0}
            />
          ))
        ) : (
          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-warning me-4 image_change_theme2'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold image_change_theme2'>
                {intl.formatMessage({id: 'No IP'})}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default IpAddressCard
