import React, {memo, useLayoutEffect, useTransition, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import Warning2 from '../../images/Warning2'
import Git from '../../images/Git'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import axios from 'axios'
import usePermission from '../../../../Utils/usePermission'
import {Link} from 'react-router-dom'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {Spinner} from 'react-bootstrap'
import EmptyPage from '../../emptyPage/EmptyPage'

const DrawerDags = memo(
  ({
    dragHandler,
    show,
    setShow,
    setOtherDrawer,
  }: {
    dragHandler: (item: any) => void
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setOtherDrawer: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
    const lang = useLang()?.lang.selectedLang
    const [isPending, startTransition] = useTransition()
    const [isOpen, setIsOpen] = useState<string | null>(null)
    const [type, setType] = useState<'default' | 'output' | 'input'>('default')
    const [search, setSearch] = useState('')
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>(null)
    const [filterData, setFilterData] = useState<any>(null)
    const errorHandling = useErrorHandling()
    const {getPermission, isPermission} = usePermission()
    const fetchFlowList = async () => {
      setLoading(true)
      try {
        const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/node-types/`)
        errorHandling(data.status)
        getPermission(data.status)

        if (data.status >= 200 && data.status < 300) {
          setData(data.data)
        } else {
          console.error(`Error fetching DNS data:`, data)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    function Search (prop: string) {
      if (prop.length ) {
        let newData: any = []
        startTransition(() => {
          for (let i = 0; i < data?.length; i++) {
            const Data = data[i]?.nodes?.filter((item: any) => {
              return (
                item?.data?.label?.toLowerCase().trim().includes(prop.toLowerCase().trim()) ||
                item?.data?.label_fa?.toLowerCase().trim().includes(prop.toLowerCase().trim())
              )
            })
            newData.push(...Data)
          }
          newData.sort(
            (a: any, b: any) => a.data?.label.indexOf(prop) - b.data?.label.indexOf(prop)
          )
          setFilterData(newData)
        })
      } else {
        setFilterData(null)
      }
    }
    useLayoutEffect(() => {
      fetchFlowList()
    }, [])
    return (
      <>
        {isPermission()}

        {/* {start drawer} */}
        <div
          id='kt_Drawer_Dags'
          className={`bg-body drawer drawer-end overflow-visible ${
            show ? 'drawer-on' : ''
          } work_drawer bg-secondary card`}
          data-kt-drawer-width="{default:'300px', 'lg': '300px'}"
          style={{zIndex: show ? 9 : 10, minWidth: 280, maxWidth: 280}}
        >
          <div className='p-4 drawer-content'>
            {/* Search Input */}
            <div
              className='mb-4 input-group'
              style={{flexDirection: lang === 'fa' ? 'row-reverse' : 'row'}}
            >
              <span className={`border-end-0 input-group-text`} style={{cursor: 'pointer'}}>
                <i className='text-gray-500 fa-search fas'></i>
              </span>
              <input
                type='text'
                value={search}
                className='border-start-0 form-control ps-0'
                placeholder={`${intl.formatMessage({id: 'Search'})}...`}
                onChange={(e) => {
                  Search(e.target.value)
                  setSearch(e.target.value)
                }}
              />
            </div>

            {/* Nodes Accordion */}
            <div className='custom-accordion accordion' id='kt_accordion_1'>
              {search.length 
                ? filterData?.length? filterData?.map((item: any) => (
                    <div
                      key={item.id}
                      draggable={!item.is_disabled}
                      className='mb-2 workflow-node'
                      style={{cursor: item.is_disabled ? 'not-allowed' : 'grab'}}
                      onDragStart={() => {
                        if (item.is_disabled) {
                        } else {
                          dragHandler({
                            ...item,
                            type: type,
                          })
                        }
                      }}
                    >
                      <div
                        data-tooltip-id={`${item.id}${item.id}`}
                        style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                        onClick={() => {
                          setIsOpen(`${item.id}${item.id}`)
                        }}
                      >
                        <Warning2 className='image_change_theme2' svgClassName='info-icon' />
                      </div>
                      <ReactTooltip
                        events={['click']}
                        id={`${item.id}${item.id}`}
                        place='left'
                        isOpen={isOpen === `${item.id}${item.id}` && show}
                        offset={10}
                        style={{
                          opacity: '1 !important',
                          borderRadius: 5,
                          width: 300,
                          fontSize: 12,
                          textAlign: 'center',
                          zIndex: 10001,
                          pointerEvents: 'unset',
                          cursor: 'default',
                          backgroundColor: 'var(--bs-text-gray-700)',
                          color: 'var(--bs-text-gray-300)',
                        }}
                        render={() => (
                          <div
                            className='d-flex flex-column'
                            draggable={true}
                            onDragStart={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          >
                            <div
                              className='align-self-end btn btn-icon btn-sm ms-2 pointer'
                              onClick={() => {
                                setIsOpen(null)
                              }}
                            >
                              <KTSVG
                                svgClassName='text-white svg-icon-white invert'
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon-2'
                              />
                            </div>
                            <div className='d-flex align-items-center gap-2 node-icon'>
                              <div
                                className='w-50px min-w-50px h-50px large-item'
                                style={item.icon ? {backgroundImage: `url(${item.icon})`} : {}}
                              ></div>
                              <span className='fs-2'>
                                {lang === 'fa'
                                  ? item.data.label_fa || item.data.label
                                  : item.data.label}
                              </span>
                            </div>
                            <p className='pt-4 text-start ps-3'>
                              {lang === 'fa'
                                ? item.data.details_fa || item.data.details
                                : item.data.details}
                            </p>
                            <hr />
                            <Link
                              to={`/workflow/node-types/?id=${item.id}`}
                              target='_blank'
                              className='fw-bold text-primary cursor-pointe'
                              style={{cursor: 'pointer'}}
                            >
                              {intl.formatMessage({id: 'Read more'})}
                            </Link>
                          </div>
                        )}
                      />

                      <div className='node-icon'>
                        <div
                          className='large-item'
                          style={item.icon ? {backgroundImage: `url(${item.icon})`} : {}}
                        ></div>
                      </div>
                      <p className='node-label'>
                        {lang === 'fa' ? item.data.label_fa || item.data.label : item.data.label}
                      </p>
                    </div>
                  )):<EmptyPage width={100} />
                : data?.map((items: any, ind: number) => (
                    <div className='mb-3 border rounded-3 accordion-item' key={ind + 1}>
                      <h2 className='accordion-header' id={`kt_accordion_1_header_${ind + 1}`}>
                        <button
                          onClick={() => {
                            setIsOpen(null)
                          }}
                          className='fw-semibold accordion-button collapsed fs-5 '
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#kt_accordion_1_body_${ind + 1}`}
                          aria-expanded='false'
                          aria-controls={`kt_accordion_1_body_${ind + 1}`}
                        >
                          {lang === 'fa' ? items.group_fa || items.group : items.group}
                        </button>
                      </h2>
                      <div
                        id={`kt_accordion_1_body_${ind + 1}`}
                        className='accordion-collapse collapse'
                        aria-labelledby={`kt_accordion_1_header_${ind + 1}`}
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='p-4 accordion-body'>
                          <div className='node-grid'>
                            {items.nodes?.map((item: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  draggable={!item.is_disabled}
                                  className='mb-2 workflow-node'
                                  style={{cursor: item.is_disabled ? 'not-allowed' : 'grab'}}
                                  onDragStart={() => {
                                    if (item.is_disabled) {
                                    } else {
                                      dragHandler({
                                        ...item,
                                        type: type,
                                      })
                                    }
                                  }}
                                >
                                  <div
                                    data-tooltip-id={`${item.id}${index}`}
                                    style={{cursor: 'pointer', alignSelf: 'start', width: '1.7rem'}}
                                    onClick={() => {
                                      setIsOpen(`${item.id}${index}`)
                                    }}
                                  >
                                    <Warning2
                                      className='image_change_theme2'
                                      svgClassName='info-icon'
                                    />
                                  </div>
                                  <ReactTooltip
                                    events={['click']}
                                    id={`${item.id}${index}`}
                                    place='left'
                                    isOpen={isOpen === `${item.id}${index}` && show}
                                    offset={10}
                                    style={{
                                      opacity: '1 !important',
                                      borderRadius: 5,
                                      width: 300,
                                      fontSize: 12,
                                      textAlign: 'center',
                                      zIndex: 10001,
                                      pointerEvents: 'unset',
                                      cursor: 'default',
                                      backgroundColor: 'var(--bs-text-gray-700)',
                                      color: 'var(--bs-text-gray-300)',
                                    }}
                                    render={() => (
                                      <div
                                        className='d-flex flex-column'
                                        draggable={true}
                                        onDragStart={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                        }}
                                      >
                                        <div
                                          className='align-self-end btn btn-icon btn-sm ms-2 pointer'
                                          onClick={() => {
                                            setIsOpen(null)
                                          }}
                                        >
                                          <KTSVG
                                            svgClassName='text-white svg-icon-white invert'
                                            path='/media/icons/duotune/arrows/arr061.svg'
                                            className='svg-icon-2'
                                          />
                                        </div>
                                        <div className='d-flex align-items-center gap-2 node-icon'>
                                          <div
                                            className='w-50px min-w-50px h-50px large-item'
                                            style={
                                              item.icon
                                                ? {backgroundImage: `url(${item.icon})`}
                                                : {}
                                            }
                                          ></div>
                                          <span className='fs-2'>
                                            {lang === 'fa'
                                              ? item.data.label_fa || item.data.label
                                              : item.data.label}
                                          </span>
                                        </div>
                                        <p className='pt-4 text-start ps-3'>
                                          {lang === 'fa'
                                            ? item.data.details_fa || item.data.details
                                            : item.data.details}
                                        </p>
                                        <hr />
                                        <Link
                                          to={`/workflow/node-types/?id=${item.id}`}
                                          target='_blank'
                                          className='fw-bold text-primary cursor-pointe'
                                          style={{cursor: 'pointer'}}
                                        >
                                          {intl.formatMessage({id: 'Read more'})}
                                        </Link>
                                      </div>
                                    )}
                                  />

                                  <div className='node-icon'>
                                    <div
                                      className='large-item'
                                      style={
                                        item.icon ? {backgroundImage: `url(${item.icon})`} : {}
                                      }
                                    ></div>
                                  </div>
                                  <p className='node-label'>
                                    {lang === 'fa'
                                      ? item.data.label_fa || item.data.label
                                      : item.data.label}
                                  </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          {/* </div> */}
          <button
            className='bg-secondary work_drawer_button'
            style={{border: 'none'}}
            onClick={() => {
              setShow(!show)
              if (!show) {
                setOtherDrawer(false)
              }
            }}
          >
            <div className='px-0 w-auto aside-toggle btn btn-active-color-primary btn-icon'>
              <Git className='svg-icon-2x svg-icon-success' />
            </div>
          </button>
        </div>
        {/* {end drawer} */}
      </>
    )
  }
)
export default DrawerDags
