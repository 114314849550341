import {Handle, Position, useOnSelectionChange, useReactFlow} from '@xyflow/react'
import React, {memo, useCallback, useEffect, useState} from 'react'
import {SetNodeConect} from '../../../modules/auth'
import {useDispatch} from 'react-redux'
import SquareSpinner from '../../images/Spin'
import Success from '../../images/Success'

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
  fontSize: 8,
}
function findPosition (ind: number, arr: number): string {
  switch (arr) {
    case 1:
      return '50%'
    case 2:
      if (ind === 0) {
        return '33%'
      }
      if (ind === 1) {
        return `calc(33% + ${ind * 20}px)`
      }
      return '0'
    case 3:
      if (ind === 0) {
        return '50%'
      }
      if (ind === 1) {
        return `calc(50% + ${ind * 20}px)`
      }
      if (ind % 2 === 0) {
        return `calc(50% - ${ind * 10}px)`
      }
      return `calc(50% + ${ind * 10}px)`
    case 4:
      if (ind === 0) {
        return '0%'
      }
      if (ind === 1) {
        return `33%`
      }
      if (ind === 2) {
        return `66%`
      }
      if (ind === 3) {
        return `100%`
      }
      return '0'
    case 5:
      if (ind === 0) {
        return '0%'
      }
      if (ind === 1) {
        return `25%`
      }
      if (ind === 2) {
        return `50%`
      }
      if (ind === 3) {
        return `75%`
      }
      if (ind === 4) {
        return `100%`
      }
      return '0'
    default:
      if (ind <= 5) {
        return `${20 * ind}%`
      }
      if (ind % 2 === 0) {
        return `calc(100% + ${ind * 20}px)`
      } else {
        return `calc(0% - ${ind * 20}px)`
      }
  }
}
function findSize (pn1: number, pn2: number) {
  if (pn1 > pn2) {
    if (pn1 > 5) {
      return 60 + 30 * (pn1 - 5)
    }
    return 60
  }
  if (pn2 > 5) {
    return 60 + 30 * (pn2 - 5)
  }
  return 60
}

function findColor (state: string) {
  switch (state) {
    case 'PENDING':
      return '#d25713'
    case 'COMPLETED':
      return '#388e3c'
    case 'ERROR':
      return '#e71111'
    case 'STOPPED':
      return '#7a23b8'
    default:
      return '#7a23b800'
  }
}
/**
 * CustomDefaultNode component wrapped with React.memo.
 *
 * @param {Object} props - The component props.
 * @param {any} props.data - The data associated with the node.
 * @param {boolean} props.isConnectable - Indicates if the node is connectable.
 * @param {string} props.id - The unique identifier for the node.
 *
 * @returns {JSX.Element} The rendered CustomDefaultNode component.
 *
 * @component
 *
 * @example
 * return (
 *   <CustomDefaultNode data={data} isConnectable={true} id="node-1" />
 * )
 *
 * @function
 * @name CustomDefaultNode
 *
 * @description
 * This component represents a custom node in a workflow diagram. It uses React Flow for rendering and managing the node connections.
 * The component tracks selected nodes and their connections, and conditionally renders connection handles based on the node's state.
 *
 * @hook
 * @name useDispatch
 * @description Dispatches actions to the Redux store.
 *
 * @hook
 * @name useSelector
 * @description Selects data from the Redux store.
 *
 * @hook
 * @name useState
 * @description Manages local state within the component.
 *
 * @hook
 * @name useReactFlow
 * @description Provides access to React Flow's node and edge management functions.
 *
 * @hook
 * @name useCallback
 * @description Memoizes the onChange function to prevent unnecessary re-renders.
 *
 * @hook
 * @name useOnSelectionChange
 * @description Listens for selection changes in the React Flow diagram.
 *
 * @hook
 * @name useEffect
 * @description Updates connected source and target nodes when the selected nodes change.
 *
 * @function
 * @name findPosition
 * @description Calculates the position of a port based on its index.
 *
 * @function
 * @name onChange
 * @description Handles changes in the selected nodes.
 *
 * @function
 * @name isShowTaegrt
 * @description Determines if a target handle should be shown.
 *
 * @function
 * @name isShowSourse
 * @description Determines if a source handle should be shown.
 */
export default memo(({data, id}: {data: any; isConnectable: boolean; id: string}) => {
  const dispatch = useDispatch()
  const [selectedNodes, setSelectedNodes] = useState<string[]>([])
  const [connectedSourseNodes, setConnectedSourseNodes] = useState<string[]>([])
  const [connectedTargetNodes, setConnectedTargetNodes] = useState<string[]>([])
  const {getEdges, getNode} = useReactFlow()
  const {getHandleConnections} = useReactFlow()
  const node = getNode(id)
  const onChange = useCallback(({nodes}: {nodes: any[]}) => {
    setSelectedNodes(nodes.map((node) => node.id))
  }, [])
  useOnSelectionChange({onChange})
  useEffect(() => {
    if (selectedNodes) {
      const edges = getEdges()
      const connectedSourseNodeIds = edges
        .filter((edge) => edge.source === selectedNodes[0])
        .map((edge) => edge.target)
      setConnectedSourseNodes(connectedSourseNodeIds)
      const connectedTargetNodeIds = edges
        .filter((edge) => edge.target === selectedNodes[0])
        .map((edge) => edge.source)
      setConnectedTargetNodes(connectedTargetNodeIds)
    } else {
      setConnectedSourseNodes([])
      setConnectedTargetNodes([])
    }
  }, [selectedNodes, id])
  /**
   * Determines whether a target port should be shown based on the provided port ID.
   *
   * @param {string} portId - The ID of the port to check.
   * @returns {boolean} - Returns `true` if the target port should be shown, otherwise `false`.
   *
   * The function checks the following conditions:
   * 1. If the `selectedNodes` array includes the current node's ID, the target port should not be shown.
   * 2. If the `connectedSourseNodes` array includes the current node's ID:
   *    - It filters the edges to find those connected to the first selected node.
   *    - It maps the edges to check if the source handle matches the provided port ID.
   *    - If the current node's ID is in `connectedSourseNodes` and the source handle matches the port ID, or if the current node's ID is in `selectedNodes`, the target port should not be shown.
   * 3. If none of the above conditions are met, the target port should be shown.
   */
  function isShowTarget (portId: string) {
    if (selectedNodes.includes(id)) {
      return false
    }
    if (connectedSourseNodes.includes(id)) {
      const conetedSorce = getEdges().filter((edge) => edge.source === selectedNodes[0])

      const ishHandlerConect = conetedSorce
        .map((edge) => (edge.source === selectedNodes[0] ? edge.sourceHandle : ''))
        .includes(portId)

      if ((connectedSourseNodes.includes(id) && ishHandlerConect) || selectedNodes.includes(id))
        return false

      return true
    }
    return true
  }
  /**
   * Determines whether the source port should be shown based on the provided port ID.
   *
   * @param {string} portId - The ID of the port to check.
   * @returns {boolean} - Returns `true` if the source port should be shown, otherwise `false`.
   *
   * The function checks the following conditions:
   * 1. If the `selectedNodes` array includes the current node ID, it returns `false`.
   * 2. If the `connectedTargetNodes` array includes the current node ID:
   *    - It filters the edges to find those connected to the first selected node.
   *    - It maps the edges to check if the target handle matches the provided port ID.
   *    - If the current node ID is in `connectedTargetNodes` and the port ID matches the target handle, or if the current node ID is in `selectedNodes`, it returns `false`.
   * 3. If none of the above conditions are met, it returns `true`.
   */
  function isShowSourse (portId: string) {
    if (selectedNodes.includes(id)) {
      return false
    }
    if (connectedTargetNodes.includes(id)) {
      const conetedTarget = getEdges().filter((edge) => edge.target === selectedNodes[0])

      const ishHandlerConect = conetedTarget
        .map((edge) => (edge.target == selectedNodes[0] ? edge.targetHandle : ''))
        .includes(portId)

      if ((connectedTargetNodes.includes(id) && ishHandlerConect) || selectedNodes.includes(id))
        return false

      return true
    }
    return true
  }
  function connectionLimit (port: any, limitCount = 1000) {
    return getHandleConnections({type: 'source', id: port.id, nodeId: id}).length < limitCount
      ? true
      : false
  }
  const size = findSize(data?.ports?.source?.length, data?.ports?.target?.length)
  return (
    <>
      <div className={` large_item `} style={{width: size, height: size}}>
        <div
          className={` position-relative overflow-hidden w-100 h-100`}
          style={{border: '1px solid gray', borderRadius: 5}}
        >
          {data.state === 'IN_PROGRESS' && <SquareSpinner size={size} />}
          { data.state && data.state !== 'IN_PROGRESS' &&<Success size={size} color={findColor(data.state)} />}

          {/* Container for background blur effect */}
          <div
            className='position-absolute '
            style={{
              overflow: 'hidden',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              zIndex: 0,
              background: 'rgba(175, 175, 175, 0.17)',
              backdropFilter: 'blur(3px)',
              WebkitBackdropFilter: 'blur(3px)',
            }}
          ></div>

          {/* Centered Content */}
          <div
            className='position-absolute d-flex align-items-center justify-content-center'
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // maxHeight: 50,
              // maxWidth: 50,
              width: '60%',
              height: '60%',
              zIndex: 1,
              borderRadius: 5,
            }}
          >
            <div
              className='w-100 h-100 large_item_icon    '
              style={
                //@ts-ignore
                node?.icon
                  ? //@ts-ignore
                    {backgroundImage: `url(${node?.icon})`, borderRadius: 5}
                  : {borderRadius: 5}
              }
            ></div>
          </div>
        </div>

        {data?.ports?.source?.map((port: any, index: number, arr: any[]) => {
          return (
            <Handle
              onMouseDown={() => {
                if (port.validate) {
                  dispatch(SetNodeConect([...port.validate, {nodeId: id}]))
                }
              }}
              key={index}
              type={'source'}
              position={Position.Right}
              id={port.id}
              style={{
                ...DEFAULT_HANDLE_STYLE,
                top: findPosition(index, arr.length),
                backgroundColor: port.color,
                zIndex: 2,
              }}
              isConnectableStart={connectionLimit(port)}
              className={connectionLimit(port) ? '' : 'connection_limit'}
            >
              <span
                hidden={isShowSourse(port.id)}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 15,
                  color: port.color,
                  textWrap: 'nowrap',
                }}
              >
                {port.label}
                {'  '}
                <div className='limit'>(this port has a connection limit)</div>
              </span>
            </Handle>
          )
        })}
        {data?.ports?.target?.map((port: any, index: number, arr: any[]) => {
          return (
            <Handle
              onMouseDown={() => {
                if (port.validate) {
                  dispatch(SetNodeConect([...port.validate, {nodeId: id}]))
                }
              }}
              key={index}
              type={'target'}
              position={Position.Left}
              id={port.id}
              style={{
                ...DEFAULT_HANDLE_STYLE,
                top: findPosition(index, arr.length),
                backgroundColor: port.color,
                zIndex: 2,
              }}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                hidden={isShowTarget(port.id)}
                style={{
                  position: 'absolute',
                  top: 5,
                  left: -5,
                  transform: 'translateX(-100%)',
                  color: port.color,
                }}
              >
                {port.label}
              </span>
            </Handle>
          )
        })}
        <span className='label' style={{width:size ,fontSize:size/6 ,transform:' translate(-50%, 0%)'}}>{data.label}</span>
      </div>
    </>
  )
})
