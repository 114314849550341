import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Modal} from 'bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toast} from 'sonner'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import usePermission from '../../../../Utils/usePermission'
import NotPermission from '../../components/NotPermission'
const initialValues = {
  name: '',
  description: '',
}
export default function CreateWorkflowModal () {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const {getPermission , isPermission} = usePermission()


/**
 * Closes the workflow creation modal and resets the form.
 *
 * This function performs the following actions:
 * 1. Retrieves the modal element with the ID 'kt_modal_create_workflow'.
 * 2. If the modal element exists, it initializes or gets the existing modal instance.
 * 3. Hides the modal instance to close the modal.
 * 4. Resets the form using Formik's `resetForm` method.
 */
  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_create_workflow') // Get the modal element
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement) // Initialize or get the modal instance
      modalInstance.hide() // Close the modal
    }
    formik.resetForm()
  }

/**
 * Creates a new workflow by sending a POST request to the API endpoint.
 * Displays a success or error message based on the response status.
 * Navigates to the workflow edit page if the creation is successful.
 *
 * @param {Object} body - The request payload containing the workflow details.
 * @param {string} body.name - The name of the workflow.
 * @param {string} body.description - The description of the workflow.
 * @returns {Promise<void>} - A promise that resolves when the workflow creation process is complete.
 */
  const CreateWorkflow = async (body: {name: string; description: string}) => {
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/workflow/`, body)
      errorHandling(response.status)
      getPermission(response.status)
      if (response.status >= 200 && response.status < 300) {
        toast.success(intl.formatMessage({id: 'Workflow created successfully'}))
        closeModal()
        navigate({
          pathname: '/workFlow-edit',
          search: `?id=${response.data.id}`,
        })
      } else {
        toast.error(intl.formatMessage({id: 'Failed to create workflow'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'Failed to create workflow'}))
    } finally {
      setLoading(false)
    }
  }

  /**
   * Defines the validation schema for the login form using Yup.
   *
   * @remarks
   * This schema validates the 'title' and 'type' fields of the form.
   *
   * @param intl - The internationalization object for formatting messages
   * @param findType - A function that finds and returns a scan type object based on the given type ID
   * @param type - The current selected type value
   *
   * @returns A Yup schema object that can be used for form validation
   */
  const loginSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'title is required'})),
    description: Yup.string().required(intl.formatMessage({id: 'Description is required'})),
  })

  /**
   * Initializes and configures the Formik form handling for the target creation.
   *
   * @remarks
   * This hook sets up form validation, initial values, and submission handling for the target creation form.
   *
   * @param initialValues - The initial values for the form fields.
   * @param loginSchema - The Yup validation schema for form field validation.
   * @param CreateWorkflow - The function to be called when the form is submitted successfully.
   *
   * @returns A Formik form handler object with methods and properties for form management.
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      CreateWorkflow(values)
    },
  })
  return (
    <div
      className={`modal fade pb-0`}
      id='kt_modal_create_workflow'
      style={{backdropFilter: 'blur(3px)'}}
      role='dialog'
      aria-bs-labelledby='staticBackdrop'
      data-bs-backdrop='static'
    >
        {isPermission()}
      <div className={`modal-dialog ${loading && 'skeleton'}`} style={{minWidth: '50%'}}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title'> {intl.formatMessage({id: 'Create Workflow'})}</h3>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => {
                closeModal()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 image_change_theme2'
              />
            </div>
          </div>
          <div className='modal-body'>
            <form
              className='w-100 form'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              <div className=''>
                <div className='fv-row mb-10'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <label className='form-label fs-6 fw-bolder  '>
                      {intl.formatMessage({id: 'Name'})}{' '}
                    </label>
                  </div>

                  <input
                    placeholder={intl.formatMessage({id: 'workflow name'})}
                    {...formik.getFieldProps('name')}
                    onChange={(e) => {
                      formik.setFieldValue('name', e.target.value)
                    }}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container mt-2 text-danger'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <label className='form-label fs-6 fw-bolder  '>
                      {intl.formatMessage({id: 'Description'})}{' '}
                    </label>
                  </div>

                  <textarea
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.description && formik.errors.description,
                      },
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    name='description'
                    autoComplete='off'
                    onChange={(e) => {
                      formik.setFieldValue('description', e.target.value)
                    }}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container mt-2 text-danger'>
                      <span role='alert'>{formik.errors.description}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-success'
                >
                  {intl.formatMessage({id: 'Create'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
