import React, {useState, useEffect, lazy, useContext, Suspense} from 'react'

import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import PageLoading from '../../loading/PageLoading'
import Arr001 from '../../components/Arr001'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {Button, Card} from 'react-bootstrap'
import {TaskScan} from './TaskScan'
import usePermission from '../../../../Utils/usePermission'
import {I18nContext} from '../../../../_metronic/i18n/Metronici18n'
import DAG from './DAG'

const ScanDetails = () => {
  const [loading, setLoading] = useState(true)
  const [searchParams] = useSearchParams()
  const errorHandling = useErrorHandling()
  const lang = useContext(I18nContext)?.lang.dir
  const navigate = useNavigate()
  const {isPermission, getPermission} = usePermission()
  const intl = useIntl()
  const id = searchParams.get('id')
  const fetchScanReport = async (id: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/report/${
          lang === 'rtl' ? '?language=fa' : ''
        }`
      )
      errorHandling(data.status)
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        const htmlContent = [`${data.data}`]
        const bl = new Blob(htmlContent, {type: 'text/html'})
        const a = document.createElement('a')
        a.href = URL.createObjectURL(bl)
        a.download = 'Report.html'
        a.hidden = true
        document.body.appendChild(a)
        a.click()
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/ \n`, data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <>
      {id ? (
        <div className=' position-relative'>
          {isPermission()}
          <Card className='w-100  ' style={{height: '70vh'}}>
            
              <DAG />
           
          </Card>
          <div className='mt-5 w-100 card'>
            <div className='d-flex flex-row justify-content-between align-items-center p-5 w-100'>
              <h3 className=' '>{intl.formatMessage({id: 'Vulnerabilities'})}</h3>

              <Button
                size='sm'
                variant='info'
                onClick={() => {
                  fetchScanReport(id)
                }}
              >
                {intl.formatMessage({id: 'Receive Report'})}
              </Button>
            </div>

            {/*
             * Renders a TaskScan component to display vulnerability data for a selected scan.
             *
             * @param {Object} props - The properties passed to the TaskScan component.
             * @param {Array<Object>} [props.vulnerabilityData] - An array of vulnerability objects for the selected scan.
             *                                                    Each object represents a specific vulnerability with its details.
             * @param {string | number} [props.id] - The unique identifier of the selected scan.
             *                                       This is used to fetch or identify specific scan data.
             * @returns {JSX.Element} A rendered TaskScan component displaying vulnerability information for the selected scan.
             */}
            <TaskScan id={id} />

            {/* <EmptyPage/> */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ScanDetails
