import React, {useEffect, useState, useCallback, useRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import {DateSelectArg, EventClickArg, DatesSetArg} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es'
import faLocale from '@fullcalendar/core/locales/fa'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import useErrorHandling from '../../../../../Utils/useErrorHandling'
import usePermission from '../../../../../Utils/usePermission'
import {RootState} from '../../../../../setup/redux/Store'
import {SetDataScanSchedule} from '../../../../modules/auth'
import {useIntl} from 'react-intl'

interface CachedData {
  data: any[]
  expireTime: number
}

interface CachedMonths {
  [key: string]: CachedData
}

interface MonthBoundaries {
  start: string
  end: string
  key: string
}

export default function Schedule (): JSX.Element {
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const [loading, setLoading] = useState<boolean>(false)
  const [month, setMonth] = useState('')
  const calendarRef = useRef<any>(null)
  const scanData = useSelector((state: RootState) => state?.root?.data?.dataScanSchedule)
  const lang = useLang()?.lang.selectedLang

  // Track current view to prevent redundant fetches
  const currentViewRef = useRef<string>('')

  // Cache management
  const CACHE_DURATION = 5 * 60 * 1000 // 5 minutes in milliseconds

  // Format date to YYYY-MM-DD
  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // Get first and last day of a month
  const getMonthBoundaries = (date: Date): MonthBoundaries => {
    const year = date.getFullYear()
    const month = date.getMonth()

    const firstDay = new Date(year, month - 1, 1)
    const lastDay = new Date(year, month + 2, 0)

    return {
      start: formatDate(firstDay),
      end: formatDate(lastDay),
      key: `${year}-${month + 1}`, // Cache key for this month
    }
  }

  const fetchScanData = useCallback(
    async (startDate: string, endDate: string, monthKey: string): Promise<void> => {
      // Check if we have valid cached data for this month
      const now = new Date().getTime()
      const cachedData = scanData.data?.[monthKey]

      if (scanData?.expireTime && cachedData && scanData?.expireTime >= now) {
        console.log(`Using cached data for ${monthKey}`)
        return
      }

      // Don't fetch if we're already loading data
      if (loading) return

      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/scan-schedule/?start_time=${startDate}T00:00&end_time=${endDate}T23:59`
        )
        errorHandling(response.status)
        getPermission(response.status)

        if (response.status >= 200 && response.status < 300) {
          const expireTime = now + CACHE_DURATION

          // Update cache
          dispatch(SetDataScanSchedule({...scanData.data, [monthKey]: response.data}))

          // Update Redux store
          // dispatch(
          //   SetDataScanSchedule({
          //     data: response.data,
          //     expireTime,
          //   })
          // )
        } else {
          console.error(
            `Error fetching data from ${process.env.REACT_APP_API_ENDPOINT}/scan-schedule/`,
            response
          )
        }
      } catch (e) {
        console.error('Error fetching scan schedule data:', e)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, errorHandling, getPermission, loading]
  )

  // Handle date navigation (prev/next month, etc.)
  const handleDatesSet = useCallback(
    (dateInfo: DatesSetArg): void => {
      const calendarApi = calendarRef.current?.getApi()
      if (!calendarApi) return
      const {start, end} = dateInfo
      const boundaries = getMonthBoundaries(start)
      setMonth(boundaries.key)
      console.log("aaa",dateInfo)
      const viewKey = `${boundaries.key}-${calendarApi.view.type}`
      // Skip if this is the same view we're already showing
      if (currentViewRef.current === viewKey) {
        return
      }

      // Update current view tracking
      currentViewRef.current = viewKey

      // Now fetch the data
      fetchScanData(boundaries.start, boundaries.end, boundaries.key)
    },
    [fetchScanData]
  )

  // Initialize with current month on component mount - only once
  useEffect(() => {
    const currentDate = new Date()
    const boundaries = getMonthBoundaries(currentDate)
    const initialViewKey = `${boundaries.key}-dayGridMonth`
    setMonth(boundaries.key)
    // Set initial view key
    currentViewRef.current = initialViewKey

    // Check if we need to fetch data
    const now = new Date().getTime()
    const cachedData = scanData?.data?.[boundaries.key]

    if (scanData?.expireTime && cachedData && scanData?.expireTime >= now) {
      console.log(`Using cached data for initial load ${boundaries.key}`)
    } else {
      fetchScanData(boundaries.start, boundaries.end, boundaries.key)
    }
  }, []) // Empty dependency array ensures this only runs once
  console.log(scanData?.data?.[month])

  return (
    <div className={'card p-4 relative' + (loading ? ' skeleton' : '')}>
      {isPermission()}
      <h1 className='pb-2'>{intl.formatMessage({id: 'Schedule'})}</h1>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        events={scanData?.data?.[month] || []}
        locales={[esLocale, faLocale]}
        locale={lang === 'fa' ? 'fa' : 'en'}
        eventClick={(arg: EventClickArg) => {
          console.log('object', arg)
        }}
        datesSet={handleDatesSet}
        headerToolbar={{
          left: 'prev,today,next',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        }}
        dayMaxEventRows={true}
        views={{
          timeGrid: {
            dayMaxEventRows: 6,
          },
        }}
      />
    </div>
  )
}
