import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {Button, Card, Row, Col, Tab, Tabs, Form} from 'react-bootstrap'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_en from 'react-date-object/locales/gregorian_en'
import {toast} from 'sonner'
import axios from 'axios'
import {SetDataScan, SetDataScanDAG} from '../../../modules/auth'
import {TargetList, ScanProfile, Result} from '../../../modules/types/scan'
import Lock from '../../images/Lock'
import Warning from '../../images/Warning'
import CreateTargetModal from './CreateTargetModal'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {RootState} from '../../../../setup/redux/Store'
import Trash from '../../images/Trash'
import usePermission from '../../../../Utils/usePermission'

export default function CreateScan () {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const dags = useSelector((state: RootState) => state?.root?.data?.dataScanDAG)
  const scans = useSelector((state: RootState) => state?.root?.data?.dataScan.data)
  const lang = useLang()?.lang.selectedLang

  const [loading, setLoading] = useState(false)
  const [loadingTarget, setLoadingTarget] = useState(false)
  const [loadingScanProfile, setLoadingScanProfile] = useState(false)
  const [targetList, setTargetList] = useState<TargetList[] | null>()
  const [target, setTarget] = useState<TargetList | null>(null)
  const [start, setStart] = useState<any>(null)
  const [time, setTime] = useState('')
  const [workflow, setWorkflow] = useState<ScanProfile | null>(null)
  const [targetPage, setTargetPage] = useState<1 | 2>(1)
const {getPermission , isPermission} = usePermission()
  // Fetch targets for selected workflow
  const fetchTargetData = async (id?: string) => {
    setLoadingTarget(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/workflow/${id || workflow?.id}/available-targets/`
      )
      if (response.status >= 200 && response.status < 300) {
        setTargetList(response.data)
      } else {
        toast.error(intl.formatMessage({id: 'Failed to fetch targets'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'Failed to fetch targets'}))
    } finally {
      setLoadingTarget(false)
    }
  }

  // Fetch scan profiles
  const fetchDagsData = async () => {
    setLoadingScanProfile(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/`)
      getPermission(response.status)
      if (response.status >= 200 && response.status < 300) {
        dispatch(SetDataScanDAG(response.data))
      } else {
        toast.error(intl.formatMessage({id: 'Failed to fetch workflow'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'Failed to fetch workflow'}))
    } finally {
      setLoadingScanProfile(false)
    }
  }

  // Create new scan
  const createScan = async (starting?: 'no') => {
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/scan/`, {
        rate: time || 'once',
        start_time:
          starting === 'no' ? null : start?.unix ? new Date(start.unix * 1000).toISOString() : null,
        target: target?.id,
        workflow: workflow?.id,
      })

      if (response.status >= 200 && response.status < 300) {
        navigate('/scan-history')
        toast.success(intl.formatMessage({id: 'Scan created successfully'}))
        if (scans?.current_page === 1) {
          const newScan: Result = {
            id: Math.random().toString(),
            target: target?.title || '_',
            description: '_',
            profile_name: workflow?.name || '_',
            status: 'running',
            start_time:
              starting === 'no'
                ? new Date(Date.now()).toISOString()
                : start?.unix
                ? new Date(start.unix * 1000).toISOString()
                : new Date(Date.now()).toISOString(),
            start_time_jalali: '',
          }
          const copy = structuredClone(scans)
          copy.results.unshift(newScan)
          dispatch(SetDataScan(copy))
        }
      } else {
        toast.error(intl.formatMessage({id: 'Failed to create scan'}))
      }
    } catch (error) {
      toast.error(intl.formatMessage({id: 'Failed to create scan'}))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const nowDate = new Date().getTime()
    if (!dags?.expireTime || dags.expireTime < nowDate || !dags?.data) {
      fetchDagsData()
    }
  }, [])
  //    Scroll to bottom when target is selected and verified
  useEffect(() => {
    if (
      (target?.is_verified === true && workflow?.is_verify_required) ||
      (workflow?.is_verify_required === false && target)
    ) {
      document.getElementById('root')?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }, [target])
  return (
    <div className='p-4 w-100 container-fluid position-relative '>
      {/* Header */}
      {isPermission()}
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h2 className='m-0'>{intl.formatMessage({id: 'Create New Scan'})}</h2>

      </div>

      <Row className='g-4'>
        {/* Scan Profile Selection */}
        <Col md={6}>
          <Card className='shadow-sm h-100'>
            <Card.Header className='d-flex justify-content-between align-items-center bg-light'>
              <h4 className='m-0'>{intl.formatMessage({id: 'Workflow'})}</h4>
              <Link to='/workflow'>
                <Button size='sm' variant='primary'>
                  {intl.formatMessage({id: 'Create Workflow'})}
                </Button>
              </Link>
            </Card.Header>
            <Card.Body className={`${loadingScanProfile ? 'skeleton' : ''}`}>
              <div className='scan-profiles-list' style={{maxHeight: '60vh', overflowY: 'auto'}}>
                {dags?.data?.map((profile, index) => (
                  <div
                    key={profile.id}
                    onClick={() => {
                      if (profile.is_available && workflow?.id !== profile.id) {
                        setWorkflow(profile)
                        fetchTargetData(profile?.id)
                        setTarget(null)
                      }
                    }}
                    className={`
                      p-3 mb-3 rounded cursor-pointer transition 
                      ${workflow?.id === profile.id ? 'bg-primary text-white' : 'bg-light'}
                      ${!profile.is_available ? 'opacity-50' : 'hover-shadow'}
                    `}
                    style={{cursor: profile.is_available ? 'pointer' : 'not-allowed'}}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <h5 className='mb-2'>{profile.name}</h5>
                      {!profile.is_available && (
                        <div className='d-flex align-items-center'>
                          <span
                            data-tooltip-id={`profile-tooltip-${index}`}
                            data-tooltip-content={intl.formatMessage({
                              id: 'You do not have permission to access this feature',
                            })}
                          >
                            <Lock className='text-danger' />
                          </span>
                          <ReactTooltip id={`profile-tooltip-${index}`} />
                        </div>
                      )}
                    </div>
                    <p className='mb-0 small'>{profile.description}</p>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Target Selection */}
        <Col md={6}>
          <Card className='shadow-sm h-100'>
            <Card.Header className='d-flex justify-content-between align-items-center bg-light'>
              <h4 className='m-0'>{intl.formatMessage({id: 'Target'})}</h4>
              <div className='d-flex gap-3'>
                {target?.is_verified === false && workflow?.is_verify_required && (
                  <Button
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_target'
                    onClick={() => {
                      setTargetPage(2)
                    }}
                    variant='warning'
                    size='sm'
                  >
                    {intl.formatMessage({id: 'Verify now'})}
                  </Button>
                )}
                {workflow && (
                  <Button
                    size='sm'
                    variant='primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_target'
                  >
                    {intl.formatMessage({id: 'Create New Target'})}
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body className={`${loadingTarget ? 'skeleton' : ''}`}>
              <div className='targets-list' style={{maxHeight: '60vh', overflowY: 'auto'}}>
                {targetList ? (
                  targetList.length > 0 ? (
                    targetList.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => setTarget(item)}
                        className={`
                          p-3 mb-3 rounded cursor-pointer transition
                          ${
                            target?.id === item.id
                              ? item.is_verified || !workflow?.is_verify_required
                                ? 'bg-primary text-white'
                                : 'bg-warning'
                              : 'bg-light'
                          }
                          hover-shadow
                        `}
                      >
                        <div className='d-flex justify-content-between align-items-center'>
                          <span>{item.title}</span>
                          {!item.is_verified && workflow?.is_verify_required && (
                            <Warning className='svg-icon-danger' />
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='p-4 text-center'>
                      <p>{intl.formatMessage({id: 'No targets available'})}</p>
                    </div>
                  )
                ) : (
                  <div className='p-4 text-center'>
                    <p>{intl.formatMessage({id: 'First select Scan profile'})}</p>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Schedule Section */}
      {((target?.is_verified === true && workflow?.is_verify_required) ||
        (workflow?.is_verify_required === false && target)) && (
        <Card className='shadow-sm mt-4'>
          <Card.Body>
            <Tabs defaultActiveKey='rate' className='mb-4'>
              <Tab eventKey='rate' title={intl.formatMessage({id: 'Rate'})}>
                <Form.Group className='mb-4'>
                  <Form.Select
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className='form-select-lg'
                  >
                    <option value=''>{intl.formatMessage({id: 'Select rate'})}</option>
                    <option value='once'>{intl.formatMessage({id: 'Once'})}</option>
                    <option value='daily'>{intl.formatMessage({id: 'Daily'})}</option>
                    <option value='weekly'>{intl.formatMessage({id: 'Weekly'})}</option>
                    <option value='monthly'>{intl.formatMessage({id: 'Monthly'})}</option>
                  </Form.Select>
                  <Button
                    variant='primary'
                    size='lg'
                    disabled={!time || loading}
                    onClick={() => {
                      setStart(null)
                      createScan('no')
                    }}
                    className='mt-4'
                  >
                    {loading ? (
                      <span>
                        <span className='spinner-border-sm spinner-border me-2' />
                        {intl.formatMessage({id: 'Creating...'})}
                      </span>
                    ) : (
                      intl.formatMessage({id: 'Create Scan'})
                    )}
                  </Button>
                </Form.Group>
              </Tab>

              <Tab eventKey='schedule' title={intl.formatMessage({id: 'Schedule'})}>
                <div className='d-flex align-items-center gap-3'>
                  <DatePicker
                    format='YYYY/MM/DD - HH:mm'
                    value={start}
                    calendarPosition='top-center'
                    plugins={[<TimePicker position='bottom' />]}
                    calendar={lang === 'fa' ? persian : gregorian}
                    locale={lang === 'fa' ? persian_fa : gregorian_en}
                    className='bg-dark'
                    onChange={(date) => setStart(date)}
                    render={
                      <input
                        placeholder={intl.formatMessage({id: 'Schedule'})}
                        className={'form-select-lg form-control text-end'}
                        style={{direction: 'ltr', textAlign: lang === 'fa' ? 'right' : 'left'}}
                        type='text'
                        name='schedule'
                        autoComplete='off'
                      />
                    }
                  />
                  <button
                    className={`col-auto border border-0 text-white p-0 m-0`}
                    style={{backgroundColor: 'transparent'}}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setStart(null)
                    }}
                  >
                    <Trash className='svg-icon-2x svg-icon-danger' />
                  </button>
                </div>
               
                  <Button
                    className='mt-4'
                    variant='primary'
                    size='lg'
                    disabled={!start || loading}
                    onClick={() => createScan()}
                  >
                    {intl.formatMessage({id: 'Save'})}
                  </Button>
                
              </Tab>
            </Tabs>

            <div className='d-flex gap-3'></div>
          </Card.Body>
        </Card>
      )}

      <CreateTargetModal
        workflowId={workflow?.id ?? null}
        needVerify={!!workflow?.is_verify_required}
        get={fetchTargetData}
        id={target?.id}
        setId={setTarget}
        setTargetPage={setTargetPage}
        targetPage={targetPage}
      />
    </div>
  )
}
