import {useIntl} from 'react-intl'
import {Button} from 'react-bootstrap'
import {KeyboardEvent, useEffect, useRef, useState} from 'react'
// import {AI} from '../../../modules/auth/redux/DEMO_DATA'
import Starts from '../../images/Starts'
import {KTSVG} from '../../../../_metronic/helpers'
import {text} from './AiDemoData'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
const AI = [
  `
  1. Take the target domain: "example.com".
  2. Enumerate subdomains using a subdomain-discovery API or tool (e.g., Amass, Subfinder, or other tools).
  3. Filter out duplicates and remove any malformed entries.
  4. For each active subdomain, run a comprehensive vulnerability check`,
  ``,
]
export default function AiGenerator ({
  generate,
  status,
  example = '',
}: // think,
// setThink,
{
  status: 'pending' | 'success' | 'failed'
  generate: (content?: string) => void
  example?: string | null | undefined
  // think: string[]
  // setThink: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const lang = useLang()?.lang.dir
  const intl = useIntl()
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [indexPlaceholder, setIndexPlaceholder] = useState(0)
  const [value, setValue] = useState('')
  const [think, setThink] = useState('')
  const [ind, setInd] = useState(0)
  const [isLang, setIsLang] = useState<'ltr' | 'rtl'>(lang || 'ltr')

  // useEffect(() => {
  //   const modalElement = document.getElementById('kt_modal_AI2')
  //   console.log('event')
  //   if (modalElement) {
  //     const handleShown = () => {
  //       if (textareaRef.current) {
  //         textareaRef.current.focus()
  //         console.log('Focused:', document.activeElement === textareaRef.current)
  //       }
  //     }

  //     // Attach event listener for when the modal is fully shown
  //     modalElement.addEventListener('shown.bs.modal', handleShown)

  //     return () => {
  //       modalElement.removeEventListener('shown.bs.modal', handleShown)
  //     }
  //   }
  // }, [])

  /**

Changes placeholder text by moving through predefined AI suggestions

@param {'mines'|'pluse'} prop - Direction to move through suggestions
*/
  function changePlaceholder (prop: 'mines' | 'pluse') {
    if (!value) {
      if (prop === 'pluse') {
        setIndexPlaceholder((prev) => (prev < AI.length - 1 ? prev + 1 : 0))
      } else {
        setIndexPlaceholder((prev) => (prev > 0 ? prev - 1 : AI.length - 1))
      }
    }
  }

  /**
  
  Selects current placeholder text if no value is entered
  */
  function selectPlaceholder () {
    if (!value) {
      setValue(AI[indexPlaceholder] || '')
    }
  }

  /**
  
  Handles keyboard navigation for placeholder suggestions
  
  @param {KeyboardEvent} e - Keyboard event
  */
  function keydown (e: KeyboardEvent<HTMLDivElement>) {
    if (e.code === 'Tab') {
      selectPlaceholder()
    }
    if (e.code === 'ArrowRight') {
      changePlaceholder('pluse')
    }
    if (e.code === 'ArrowLeft') {
      changePlaceholder('mines')
    }
  }

  /**
  
  Closes the Bootstrap modal programmatically
  */
  const isRTL = (text: string) => {
    const rtlChars = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/
    return rtlChars.test(text)
  }
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (status === 'pending') {
      if (ind === 0) {
        setTimeout(() => {
          setThink(text[ind])
          setInd(1)
        }, 1000)
      } else {
        interval = setTimeout(() => {
          if (text.length >= ind) {
            console.log(ind)
            setThink(text[ind])
            setInd(ind + 1)
          }
        }, 3000)
      }
    } else {
      console.log('first')
      setInd(0)
      clearTimeout(interval)
    }
    if (status === 'success') {
      setValue('')
    }
    return () => {
      if (interval) {
        clearTimeout(interval)
      }
    }
  }, [status, think])
  return (
    <>
      <div
        className={`modal fade `}
        id='kt_modal_AI3'
        role='dialog'
        aria-bs-labelledby='staticBackdrop'
        data-bs-backdrop='static'
        style={{backdropFilter: 'blur(3px)'}}
      >
        <div
          onKeyDown={(e) => keydown(e)}
          className='modal-dialog p-0 '
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header p-2 justify-content-between align-item-center px-4'>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-bs-label='Close'
                onClick={() => {
                  if (status !== 'pending') setValue('')
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
              {think && <p className=' abc p-0 m-0'>{think}</p>}

              <Button
                size='sm'
                className='btn btn-icon btn-sm btn-active-light-primary ms-2  p-2'
                style={{width: 'fit-content'}}
                onClick={() => {
                  generate(value)
                  // setValue('')
                  setIndexPlaceholder(0)
                  //   closeModal()
                }}
              >
                <Starts className='image_change_theme' /> {intl.formatMessage({id: 'Generate'})}
              </Button>
            </div>

            <div className={`modal-body h-400px p-0 position-relative  `}>
              <textarea
                dir={isLang}
                ref={textareaRef}
                value={value}
                className={` w-100  bg-transparent position-relative ${
                  status === 'pending' && 'text-muted'
                }`}
                style={{resize: 'none', outline: 'none', border: 'none', height: 350, zIndex: 2}}
                onChange={(e) => {
                  setIsLang(isRTL(e.target.value) ? 'rtl' : 'ltr')
                  setValue(e.target.value)
                }}
              />

              {!value && status !== 'pending' && (
                <pre
                  className='w-100 position-absolute overflow-hidden top-0 left-0 text-gray-500 abc'
                  style={{height: 350, zIndex: '1'}}
                >
                  {AI[indexPlaceholder] || ''}
                </pre>
              )}
              <div className='d-flex align-items-center justify-content-between w-100 h-50px px-4'>
                <div className=' d-flex align-items-center gap-2'>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary p-1'
                    onClick={selectPlaceholder}
                  >
                    TAB
                  </Button>
                  {intl.formatMessage({id: 'To choose from tips'})}
                </div>
                <div className=' d-flex align-items-center gap-2'>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary fw-bolder  px-2'
                    onClick={() => {
                      changePlaceholder('mines')
                    }}
                  >
                    {`<`}
                  </Button>
                  <Button
                    size='sm'
                    className='btn btn-icon btn-sm btn-active-light-primary fw-bolder  px-2'
                    onClick={() => {
                      changePlaceholder('pluse')
                    }}
                  >{`>`}</Button>
                  {intl.formatMessage({id: 'Arrow keys to move tips'})}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
