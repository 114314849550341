import {
  ReactFlow,
  Controls,
  ReactFlowProvider,
} from '@xyflow/react'
import '../../workFlow/components/WorkFlow.scss'
import CustomEdge from '../../workFlow/components/CustomEdge'
import {memo, useLayoutEffect, useState} from 'react'
import CustomDefaultNode from '../../workFlow/components/CustomDefaultNode'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import { useSearchParams } from 'react-router-dom'

/**
 * Custom edge interface extending Edge with optional markerEnd
 */
// interface CustomEdge extends Edge {
//   markerEnd?: string // Define markerEnd as an optional string
// }
const nodeTypes = {
  default: CustomDefaultNode,
}
// Define the types of edges and nodes used in the flow
const edgeTypes = {
  default: CustomEdge,
}

// Main component for the Directed Acyclic Graph (DAG)
const DAG = memo (() => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  let longPulling : NodeJS.Timeout
  const [data, setData] = useState<{
    nodes: any[]
    edges: any[]
  }>({nodes: [], edges: []})
  const errorHandling = useErrorHandling()
  const fetchScanDetails = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/workflow-state/`)
      errorHandling(data.status)
      if(data.status >= 400  && data.status < 500){
        console.log('gggggg')
        clearTimeout(longPulling)
        return
      }
      if (data.status >= 200 && data.status < 300) {
        console.log(data.data)
        setData({
          nodes: data.data.nodes,
          edges: data.data.connectors,
        })
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
    longPulling = setTimeout(()=>{
      fetchScanDetails()
    },30000)
  }
  useLayoutEffect(() => {
    if (id) {
      fetchScanDetails()
    }
    return ()=>{clearTimeout(longPulling)}
  }, [id])
  return (
    <>
      {/* {start workflow} */}
      <div
        style={{width: '100%', height: '100% ', direction: 'ltr'}}
        className={`${loading ? 'skeleton' : ''}`}
      >
        <ReactFlowProvider>
          <ReactFlow
            disableKeyboardA11y={true}
            nodeTypes={nodeTypes}
            nodes={data.nodes || []}
            edges={data.edges || []}
            snapToGrid={true}
            edgeTypes={edgeTypes}
            fitView={true}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            // panOnScroll
            panOnDrag={[2]}
          >
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
      {/* {end workflow} */}
    </>
  )
})

export default DAG
