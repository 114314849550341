import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {OptionsType, SelectedType} from '../../modules/types/filters'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../_metronic/helpers'
import Close from '../images/Close'
import { Button } from 'react-bootstrap'

export default function Filter ({
  get,
  filterOptionDomains,
  selected,
  filterSelected,
}: {
  get: any
  filterOptionDomains: OptionsType
  selected: SelectedType
  filterSelected: any
}) {
  const dispatch = useDispatch()
  const intl = useIntl()
  function selectedHandel (items: string, item: string) {
    let copy = structuredClone(selected)
    if (copy?.[items] && copy[items].length && copy[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy?.[items]?.length) {
        copy[items].push(item)
      } else {
        if (copy) {
          copy[items] = [item]
        } else {
          copy = {[items]: [item]}
        }
      }
    }
    dispatch(filterSelected(copy))
  }
  function selectedCategory (item: string) {
    let copy = structuredClone(selected)
    if (!(item in (selected || {}))) {
      copy = {...copy, [item]: []}
    }
    dispatch(filterSelected(copy))
  }
  function delete_ (item: string) {
    let copy = structuredClone(selected)
    if (item in (copy || {})) {
      delete copy?.[item]
    }
    dispatch(filterSelected(copy))
  }

  return (
    <div className='w-100 p-4 '>
      <div>
        <div className='w-100 h-40px border border-primary  d-flex align-items-center rounded-3 dropdown bg-gray-100 '>
          <Button className=' rounded-end-0 px-2 py-3 mh-100 me-2' onClick={()=>{ get({filterProps:selected})}}><KTIcon iconName='filter' className=' image_change_theme2 fs-2' /></Button>
          <div className='d-flex align-items-center gap-2'>
            {Object.keys(selected || {}).map((items, idx ,arr) => (
                
                <div className='input-group d-flex flex-nowrap' style={{width: 'fit-content'}}
                key={idx}>
                  <div className='input-group-prepend ' style={{height: 27}}>
                    <label
                      className='input-group-text p-1 rounded-end-0 '
                      htmlFor='inputGroupSelect01'
                      style={{height: 27}}
                    >
                      {items}
                    </label>
                  </div>
                  <div
                    className='border border-primary p-1 d-flex align-items-cente  dropdown bg-gray-200'
                    style={{height: 27}}
                  >
                    <div
                      className='d-flex  min-w-60px align-items-center  flex-shrink-1 w-100 gap-1 dropdown-toggle '
                      id='dropdownMenu'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                   <div className='w-100'>
                        {selected?.[items].map((i, ind) => (
                          <span key={ind}> {`${ind ? ', ':''}${i}`} </span>
                        ))}
                   </div>
                    </div>
                    <div className='dropdown-menu  mh-200px overflow-auto' aria-labelledby='dropdownMenu'>
                      {filterOptionDomains?.[items].map((item, idx) => (
                        <a
                          onClick={() => {
                            selectedHandel(items, item.name)
                          }}
                          className={`dropdown-item fw-bold  ${
                            selected?.[items]?.find((i)=>i===item.name) && 'active'
                          }`}
                          key={idx}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className='input-group-append'>
                    <div
                      className=' p-1 rounded-start-0  input-group-text'
                      style={{height: 27}}
                      onClick={() => delete_(items)}
                    >
                      <Close className=' svg-icon-2x image_change_theme2' />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className='drop flex-shrink-1 w-100 h-100   '>
            <div
              className='d-flex py-2 align-items-center  h-100 flex-shrink-1 w-100 gap-1 dropdown-toggle '
              id='dropdownMenuButton'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            ></div>
            <div className='dropdown-menu w-75' aria-labelledby='dropdownMenuButton'>
              {Object.keys(filterOptionDomains || {}).map((item, idx) => (
                <a
                  onClick={() => {
                    selectedCategory(item)
                  }}
                  className={`dropdown-item fw-bold ${item in (selected || {}) && 'active'}`}
                  key={idx}
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
