import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

// Icons
import Sort from '../images/Sort'
import Search from '../images/Search'
import Close from '../images/Close'

/**
 * SearchAndSort - A comprehensive search and sorting component
 * 
 * @component
 * @description Provides advanced search and sorting functionality for lists
 */

interface SearchItem {
  name: string
  value: string
}

interface SortOption {
  value: string
  name: string
}

interface Props {
  search: SearchItem[] | never[]
  setSearch: ActionCreatorWithPayload<any, any>
  getData: (params: { sort?: string }) => void
  sort: any
  setSort: ActionCreatorWithPayload<any, any>
  sortOption: SortOption[] | any[]
  searchOption: string[] | null
  className?: string
}

export default function SearchAndSort({
  search,
  setSearch,
  getData,
  sort,
  setSort,
  sortOption,
  searchOption,
  className = '',
}: Props) {
  const dispatch = useDispatch()
  const intl = useIntl()

  // Sort handlers
  const handleSortAsc = (args: any) => {
    const sortValue = dispatch(setSort(args)).payload.value
    getData({ sort: `&ordering=${sortValue}` })
  }

  const handleSortDesc = (args: any) => {
    const sortValue = dispatch(setSort(args)).payload.value
    getData({ sort: `&ordering=${sortValue}` })
  }

  // Search handlers
  const handleDeleteSearch = (index: number) => {
    const updatedSearch = [...search]
    updatedSearch.splice(index, 1)
    dispatch(setSearch(updatedSearch))
  }

  const handleAddSearch = (name: string) => {
    const updatedSearch = [...search]
    updatedSearch.push({ name, value: '' })
    dispatch(setSearch(updatedSearch))
  }

  const handleUpdateSearchValue = (value: string, index: number) => {
    const updatedSearch = structuredClone(search)
    updatedSearch[index].value = value
    dispatch(setSearch(updatedSearch))
  }

  return (
    <div className={`d-flex flex-column flex-md-row gap-3 ${className}`}>
      {/* Search Section */}
      <div className="flex-grow-1 position-relative w-100">
        <Dropdown className="w-100">
          <Dropdown.Toggle
            as={CustomSearchToggle}
            id="search-dropdown"
            className="w-100 border-0 shadow-sm rounded-3 bg-gray-900 p-0 h-46px min-h-46px"
          >
            <div className="d-flex align-items-center  ps-3 pe-2 rounded-3 bg-gray-600 h-46px w-100" style={{padding:1 ,minHeight: 46,
            }}>
              <div className=" fw-bold me-2 ">
                {intl.formatMessage({ id: 'Search' })}:
              </div>
              
              <div className="d-flex  gap-2 py-1 w-100 flex-grow-1 overflow-x-auto">
                {search.length === 0 && (
                  <span className=" text-gray-300 fst-italic">
                    {intl.formatMessage({ id: 'Select search criteria' })}
                  </span>
                )}
                
                {search.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center px-3  rounded-pill bg-light border"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <span className="text-capitalize fw-medium  pe-2 border-end border-secondary">
                      {item.name}
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-sm border-0 h-40px bg-transparent py-1 px-2"
                      style={{ width: '110px', minWidth: '80px' }}
                      placeholder={intl.formatMessage({ id: 'Search' })}
                      value={item.value}
                      onChange={(e) => handleUpdateSearchValue(e.target.value, index)}
                    />
                    <button
                      className="btn btn-sm p-0 ms-1 text-secondary border-0"
                      onClick={() => handleDeleteSearch(index)}
                    >
                      <Close className="svg-icon svg-icon-1 image_change_theme2" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100 shadow rounded-3 border-0 py-2">
            <div className="px-3 py-1 mb-2 border-bottom text-muted small">
              {intl.formatMessage({ id: 'Select search criteria' })}
            </div>
            {searchOption?.map((item, index) => (
              <Dropdown.Item
                key={index}
                className={`px-3 py-2 text-capitalize ${
                  search.some((i) => i.name === item) ? 'bg-light' : ''
                }`}
                onClick={() => {
                  if (!search.some((i) => i.name === item)) handleAddSearch(item)
                }}
              >
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Controls Section */}
      <div className="d-flex gap-2">
        {/* Search Button */}
        <Button
          className="d-flex align-items-center justify-content-center shadow-sm"
          style={{ width: '46px', height: '46px' }}
          variant="primary"
          onClick={() => getData({})}
        >
          <Search className="svg-icon svg-icon-2 svg-icon-white" />
        </Button>

        {/* Sort Controls */}
        <div className="d-flex align-items-center gap-2">
          {/* Ascending Sort */}
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              className="shadow-sm d-flex align-items-center justify-content-center"
              style={{ width: '46px', height: '46px' }}
              id="asc-sort-dropdown"
            >
              <Sort className="svg-icon svg-icon-2 text-primary image_change_theme2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow rounded-3 border-0 py-2">
              <div className="px-3 py-1 mb-2 border-bottom text-muted small">
                {intl.formatMessage({ id: 'Sort Ascending' })}
              </div>
              {sortOption.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  className={`px-3 py-2 text-capitalize ${
                    item.value === sort.sortAsc ? 'bg-light fw-bold' : ''
                  }`}
                  onClick={() => handleSortAsc({ value: item.value, type: 'asc' })}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* Descending Sort */}
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              className="shadow-sm d-flex align-items-center justify-content-center"
              style={{ width: '46px', height: '46px' }}
              id="desc-sort-dropdown"
            >
              <Sort
                className="svg-icon svg-icon-2 text-primary image_change_theme2"
                svgClassName="rotate_180"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow rounded-3 border-0 py-2">
              <div className="px-3 py-1 mb-2 border-bottom text-muted small">
                {intl.formatMessage({ id: 'Sort Descending' })}
              </div>
              {sortOption.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  className={`px-3 py-2 text-capitalize ${
                    `-${item.value}` === sort.sortDes ? 'bg-light fw-bold' : ''
                  }`}
                  onClick={() => handleSortDesc({ value: `-${item.value}`, type: 'des' })}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

// Custom dropdown toggle to prevent dropdown from closing when clicking on input fields
const CustomSearchToggle = React.forwardRef(({ children, onClick }:any, ref:any) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: 'pointer' }}
  >
    {children}
  </div>
));