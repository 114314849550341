import React, { useRef } from 'react';
import { useField } from 'formik';

interface FormikFileInputProps {
  name: string;
  className?: string;
  setFieldValue: (field: string, value: any) => void;
  accept?: string;
}

const FormikFileInput: React.FC<FormikFileInputProps> = ({
  name,
  className = '',
  setFieldValue,
  accept = '*/*',
}) => {
  const [field, meta] = useField(name);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(name, reader.result as string);
      };
      reader.onerror = () => {
        setFieldValue(name, '');
      };
      reader.readAsDataURL(file);
    }
  };

  const clearFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setFieldValue(name, '');
    }
  };

  return (
    <div className="mb-4">

      
      <div className="input-group">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''} ${className}`}
          accept={accept}
        />
        {field.value && (
          <button 
            type="button" 
            className="btn btn-outline-secondary" 
            onClick={clearFile}
          >
            Clear
          </button>
        )}
      </div>

      {meta.error && meta.touched && (
        <div className="invalid-feedback d-block">
          {meta.error}
        </div>
      )}
      
      {field.value && (
        <div className="mt-2 text-muted small">
          File selected and converted to data URI
        </div>
      )}
    </div>
  );
};

export default FormikFileInput;