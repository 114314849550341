import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div>
        <SidebarMenuItem
          icon='color-swatch'
          title={intl.formatMessage({id: 'Dashboard'})}
          to='/dashboard'
        />
        <SidebarMenuItemWithSub
          icon='fingerprint-scanning'
          title={intl.formatMessage({id: 'Scan'})}
          to='/scan'
        >
          <SidebarMenuItem
            icon='questionnaire-tablet'
            title={intl.formatMessage({id: 'Scan History'})}
            to='/scan-history'
          />
          <SidebarMenuItem
            icon='watch'
            title={intl.formatMessage({id: 'Schedule'})}
            to='/scan-schedule'
          />
          <SidebarMenuItem
            icon='add-notepad'
            title={intl.formatMessage({id: 'Create Scan'})}
            to='/scan-create'
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          icon='wrench'
          title={intl.formatMessage({id: 'Toolbox'})}
          to='/Toolbox'
        >
          <SidebarMenuItem
            icon='illustrator'
            title={intl.formatMessage({id: 'AI Template'})}
            to='/Toolbox/Ai-Template'
          />
          <SidebarMenuItem
            icon='magnifier'
            title={intl.formatMessage({id: 'DNS Inspectbilities'})}
            to='/Toolbox/dns'
          />
          <SidebarMenuItem
            icon='sms'
            title={intl.formatMessage({id: 'Mail Security Scanner'})}
            to='/Toolbox/smtp'
          />
          <SidebarMenuItem
            icon='shield-cross'
            title={intl.formatMessage({id: 'WAF Security Assessment'})}
            to='/Toolbox/waf'
          />
          <SidebarMenuItem
            icon='rocket'
            title={intl.formatMessage({id: 'DoS Testing'})}
            to='/Toolbox/dos'
          />
          <SidebarMenuItem icon='lock-3' title={intl.formatMessage({id: 'SSL Check'})} to='/Toolbox/ssl' />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          icon='technology-4'
          title={intl.formatMessage({id: 'Attack Surface'})}
          to='/attack-surface'
        />

        <SidebarMenuItem icon='switch' title={intl.formatMessage({id: 'Targets'})} to='/target' />
        <SidebarMenuItem icon='educare' title={intl.formatMessage({id: 'Assets'})} to='/assets' />
        <SidebarMenuItem
          icon='cross-circle'
          title={intl.formatMessage({id: 'Vulnerabilities'})}
          to='/vulnerabilities'
        />

        <SidebarMenuItem icon='data' title={intl.formatMessage({id: 'Workflow'})} to='/workFlow' />
        <SidebarMenuItem
          icon='gear'
          title={intl.formatMessage({id: 'Integrations'})}
          to='/integrations'
        />
      </div>
    </div>
  )
}

export {SidebarMenuMain}
