import React, { useState, useEffect } from 'react';

const SquareSpinner = ({size= 58}) => {
  const [progress, setProgress] = useState(0);

  
  useEffect(() => {
    const animate = () => {
      setProgress((prev) => (prev + 0.5) % 100);
      requestAnimationFrame(animate);
    };
    
    const animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);
  
  // Calculate the path coordinates based on progress
  const getPathCoordinates = () => {
    const totalLength = (size-2) * 4; // Total perimeter length
    const currentLength = (progress / 100) * totalLength;

    if (currentLength <= (size-2)) {
      // Top edge
      return `M 0 0 L ${currentLength} 0`;
    } else if (currentLength <= (size-2) * 2) {
      // Right edge
      const rightProgress = currentLength - (size-2);
      return `M 0 0 L ${(size-2)} 0 L ${(size-2)} ${rightProgress}`;
    } else if (currentLength <= (size-2) * 3) {
      // Bottom edge
      const bottomProgress = currentLength - ((size-2) * 2);
      return `M 0 0 L ${(size-2)} 0 L ${(size-2)} ${(size-2)} L ${(size-2) - bottomProgress} ${(size-2)}`;
    } else {
      // Left edge
      const leftProgress = currentLength - ((size-2) * 3);
      const remainingHeight = (size-2) - leftProgress;
      return `M 0 0 L ${(size-2)} 0 L ${(size-2)} ${(size-2)} L 0 ${(size-2)} L 0 ${remainingHeight}`;
    }
  };

  return (
    <div className="position-absolute "
    style={{width:"100%" ,height:"100%" ,zIndex:2}}
    >
      <svg 
        width={(size-2)} 
        height={(size-2)} 
        className="absolute top-0 left-0"
      >
        {/* Square outline (gray) */}
        <path 
          d={`M 0 0 L ${(size-2)} 0 L ${(size-2)} ${(size-2)} L 0 ${(size-2)} L 0 0`}
          stroke=""
          strokeWidth={(size-2)/12}
          fill="none"
        />
        {/* Animated progress line (blue) */}
        <path
          d={getPathCoordinates()}
          stroke="#3B82F6"
          strokeWidth={(size-2)/12}
          fill="none"
        />
      </svg>
    </div>
  );
};

export default SquareSpinner;

