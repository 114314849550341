import React from 'react'
import file from '../images/file.png'
export default function EmptyPage ({width=300}) {
  return (
    <div className=' d-flex flex-column justify-content-center align-items-center w-100 h-100 gap-10'>
      <img src={file} width={width} />
      <h1>
        <strong style={{fontSize:24}}>No Data</strong>
      </h1>
    </div>
  )
}
