import React, {useState, useEffect, useLayoutEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'

import Arr001 from '../../../components/Arr001'
import ChartLine from './ChartLine'
import ImageModal from './ImageModal'
import HistoryModal from './HistoryModal'
import useErrorHandling from '../../../../../Utils/useErrorHandling'
import Timeline from './Timeline'
import {
  ASSET_DETAILS_DATA,
  ASSET_DETAILS_TIMELINE_DATA,
} from '../../../../modules/auth/redux/DEMO_DATA'
import ConvertDate from '../../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../../_metronic/helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup/redux/Store'
import usePermission from '../../../../../Utils/usePermission'
import {Badge} from 'react-bootstrap'
import {
  AssetDetails as AssetDetailsType,
  Scan,
  Vulnerability,
} from '../../../../modules/types/asset'
import DnsRecordsCard from './DnsRecordsCard'
import IpAddressCard from './IpAddressCard'

/**
 * Converts letter grades to corresponding colors.
 * @param {string} item - The letter grade to convert.
 * @returns {string} - The corresponding color code.
 */
const colorConvertor = (item: string) => {
  switch (item.toUpperCase()) {
    case 'A':
    case 'A+':
      return '#1fa055'
    case 'B':
      return '#255a8f'
    case 'C':
      return '#ffff4d'
    case 'D':
      return '#ff66a3'
    case 'F':
    case 'T':
    case 'M':
    case 'N':
      return '#FF0000'
    default:
      return ''
  }
}

/**
 * AssetDetails component displays detailed information about an asset.
 * It fetches asset data from an API and displays various details including DNS records, IP addresses, vulnerabilities, and more.
 */
const AssetDetails = () => {
  const [loading, setLoading] = useState(false)
  const [assetDetailData, setAssetDetailData] = useState<AssetDetailsType | null>(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const user = useSelector((state: RootState) => state?.root?.user)

  /**
   * Finds the current scan data based on the selected scan ID.
   * @returns {any} - The current scan data or false if it's the last scan.
   */
  const find = () => {
    const ind = assetDetailData?.scan_list?.findIndex(
      (item) => item.id === assetDetailData?.selected_scan
    )
    if (
      (assetDetailData?.scan_list?.length && ind === assetDetailData?.scan_list?.length - 1) ||
      ind === undefined ||
      !assetDetailData ||
      !assetDetailData.scan_list
    ) {
      return false
    }
    return assetDetailData?.scan_list?.[ind]
  }

  /**
   * Fetches asset detail data from the API.
   * @param {string} historyId - Optional scan ID to fetch specific scan data.
   */
  const fetchAssetDetailData = async (historyId = '') => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/assets/${searchParams.get('id')}${
          historyId ? `/?scan_id=${historyId}` : '/'
        }`
      )
      errorHandling(data.status) // Logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        setAssetDetailData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${searchParams.get(
            'id'
          )} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODE === 'demo') {
      setAssetDetailData(ASSET_DETAILS_DATA)
    } else {
      fetchAssetDetailData()
    }
  }, [])

  return (
    <>
      <>
        <div
          className={`card card-body position-relative ${loading && 'skeleton'}`}
          style={!assetDetailData && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
        >
          {isPermission()}
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex p-3 col-8 gx-0 row'>
              <div className='d-flex justify-content-between align-items-start gap-5 pt-2 h-auto'>
                <h3 className=' '>{assetDetailData?.host} </h3>
                {find() && (
                  <span className='text-danger'>
                    {intl.formatMessage({
                      id: 'Please note: The data shown here is from a past period and may not reflect the current status.',
                    })}
                  </span>
                )}
              </div>
              <div className='d-flex justify-content-between align-items-center p-2 w-100'>
                <div className='d-flex align-items-center p-1 w-200px h-auto'>
                  <div
                    className={`w-30px h-30px bg-gray-600  rounded-1 d-flex justify-content-center align-items-center p-1 me-3`}
                  >
                    {assetDetailData?.score}
                  </div>
                  {/* {assetDetailData?.targets} */}
                </div>
                {find() && (
                  <div className='d-flex flex-row align-items-center'>
                    <span className='fs-6'>{intl.formatMessage({id: 'Scan date'})}:</span>
                    <mark className='text-gray-800 fs-7 ms-3'>
                      <ConvertDate date={(find() as Scan)?.start_time} />
                    </mark>
                  </div>
                )}
                <div className='d-flex flex-row align-items-center'>
                  <span className='fs-6'>{intl.formatMessage({id: 'Last Seen'})}:</span>
                  <span className='text-gray-800 fs-7 ms-3'>
                    <ConvertDate
                      date={assetDetailData?.timestamp || ''}
                      jalali={assetDetailData?.timestamp_jalali}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className='d-flex gap-2'>
              <button
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#historyModal'
                style={{height: 'fit-content'}}
              >
                {intl.formatMessage({id: 'Scan History'})}
              </button>
              {find() && (
                <button
                  className='btn btn-info'
                  onClick={() => fetchAssetDetailData()}
                  style={{height: 'fit-content'}}
                >
                  {intl.formatMessage({id: 'Back to last scan'})}
                </button>
              )}
            </div>
          </div>
          <div className={` w-100  d-flex flex-row h-100 bg-body  `}>
            <div className='d-flex gap-5 w-100 h-100 justify-content-between'>
              <div
                className='d-flex flex-column gap-5  h-100'
                style={{minWidth: '30%', maxWidth: '33%', width: 500}}
              >
                <DnsRecordsCard dnsData={assetDetailData?.dns} />
                <IpAddressCard ipData={assetDetailData?.ip} version='ipv4' />
                <IpAddressCard ipData={assetDetailData?.ip} version='ipv6' />
              </div>

              {/* mid */}
              <div
                className='d-flex flex-column gap-5  h-100'
                style={{minWidth: '30%', maxWidth: '33%', width: 500}}
              >
                <div className='bg-mh_black p-5 card'>
                  <h2>
                    <strong>{intl.formatMessage({id: 'Live Timeline'})}</strong>
                  </h2>
                  <Timeline data={assetDetailData?.scan_list || []} />
                </div>
                <div className='bg-mh_black p-5 card'>
                  <div className='d-flex justify-content-between'>
                    <h2>
                      <strong>{intl.formatMessage({id: 'Vulnerabilities History'})}</strong>
                    </h2>
                    30 {intl.formatMessage({id: 'day'})}
                  </div>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <div>
                      <h2>
                        <strong style={{fontSize:34}}>{assetDetailData?.total_vulnerabilities || 0}</strong>
                      </h2>
                      <span style={{fontSize: 10}}>
                        {intl.formatMessage({id: 'Vulnerabilities'})}
                      </span>
                    </div>
                    <div className='flex-grow-1 min-w-100px'>
                      <ChartLine data={assetDetailData?.vulnerabilities_over_time} />
                    </div>
                  </div>
                </div>
                <div className='bg-mh_black p-5 card'>
                  <h2>
                    <strong>{intl.formatMessage({id: 'Technologies'})}</strong>
                  </h2>
                  <div className='d-flex flex-wrap gap-2 mt-5'>
                    {assetDetailData?.techs?.map((item, indx: number) => (
                      <div
                        key={indx}
                        className='d-flex flex-column justify-content-between align-items-center bg-secondary bg-gradient p-1 rounded w-fit pe-auto'
                        style={{width: 'calc(50% - 5px)', gap: 5}}
                      >
                        <div className='d-flex justify-content-between gap-2 w-100'>
                          <strong>{intl.formatMessage({id: 'Name'})} : </strong>{' '}
                          <span> {item.name}</span>
                        </div>
                        <div className='d-flex justify-content-between gap-2 w-100'>
                          <strong>{intl.formatMessage({id: 'Version'})} : </strong>{' '}
                          <span>{item.version || '_'}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='bg-mh_black p-5 card'>
                  <h2>
                    <strong>{intl.formatMessage({id: 'Ports'})}</strong>
                  </h2>
                  <div className='d-flex flex-wrap gap-2 mt-5'>
                    {assetDetailData?.ports?.map((item: any, indx: number) => (
                      <div
                        key={indx}
                        className='bg-secondary bg-gradient p-1 rounded w-fit pe-auto'
                      >
                        {item.port}
                      </div>
                    ))}
                  </div>
                </div>

                <div className='bg-mh_black p-5 card'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h2>
                        <strong>{intl.formatMessage({id: 'Certificate'})}</strong>
                      </h2>
                      <p> {intl.formatMessage({id: 'Protocols and Ciphers'})}</p>
                    </div>
                    <button
                      style={{height: 'fit-content'}}
                      className='btn btn-primary btn-sm'
                      disabled={!assetDetailData?.ssl.id}
                      onClick={() => {
                        if (assetDetailData?.ssl.id) {
                          navigate({
                            pathname: '/Toolbox/ssl-details',
                            search: `?id=${assetDetailData?.ssl.id}`,
                          })
                        }
                      }}
                    >
                      {intl.formatMessage({id: 'Show'})} {intl.formatMessage({id: 'details'})}
                    </button>
                  </div>
                  <div className='d-flex flex-wrap justify-content-between align-items-center gap-2 mt-2'>
                    {assetDetailData?.ssl.id ? (
                      <>
                        <div className='d-flex align-items-center gap-2'>
                          <div
                            className={`d-flex justify-content-center align-items-center position-relative`}
                          >
                            <KTSVG
                              path='/media/hexagonal.svg'
                              className='ms-2 svg-icon svg-icon-3x svg-icon-body'
                              fill={colorConvertor(assetDetailData?.ssl.score || 'A')}
                            />
                            <span className='position-absolute fw-bolder text-black ms-2'>
                              {assetDetailData?.ssl.score || 'A'}
                            </span>
                          </div>
                          <p className='m-0 text-capitalize'>
                            {assetDetailData?.ssl.status || ''}
                          </p>
                        </div>
                        <span>{` ${assetDetailData?.ssl.days_to_expiry || 15} day to expire`}</span>
                      </>
                    ) : (
                      <span>No Certificate</span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`d-flex  h-100 gap-5 flex-column  `}
                style={{minWidth: '30%', maxWidth: '33%', width: 500}}
              >
                <div className='d-flex flex-row p-1 p-5  card pe-3' style={{maxHeight: 600}}>
                  <div className='d-flex flex-column bg-mh_black w-100 h-100'>
                    <div className='border-2 border-gray-500 pt-3 gap-3 border-bottom w-100  fs-4'>
                      {intl.formatMessage({id: 'Associated Vulnerabilities'})}
                    </div>

                    <div className='d-flex flex-row pt-3 pb-3 w-100 min-h-40px '>
                      <div className='d-flex'>
                        <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-3 py-3 h-40px rounded-3'>
                          <span>{`${assetDetailData?.total_vulnerabilities}`}</span>
                        </div>
                        <div className={`w-2px h-100 bg-white mx-2`} />
                      </div>
                      <div className='d-flex gap-1  flex-wrap'>
                      <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-1 py-3 rounded-3'>
                        <div className={`w-15px h-15px rounded-3 bg-icritical me-2`} />
                        <span>{assetDetailData?.vulnerabilities?.critical}</span>
                      </div>
                      <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-2 py-3 rounded-3 '>
                        <div className={`w-15px h-15px rounded-3 bg-ihigh me-2`} />
                        <span>{assetDetailData?.vulnerabilities?.high}</span>
                      </div>
                      <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-2 py-3 rounded-3 '>
                        <div className={`w-15px h-15px rounded-3 bg-imedium me-2`} />
                        <span>{assetDetailData?.vulnerabilities?.medium}</span>
                      </div>
                      <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-2 py-3 rounded-3 '>
                        <div className={`w-15px h-15px rounded-3 bg-ilow me-2`} />
                        <span>{assetDetailData?.vulnerabilities?.low}</span>
                      </div>
                      <div className='d-flex mh-30px flex-row align-items-center bg-secondary px-2 py-3 rounded-3 '>
                        <div className={`w-15px h-15px rounded-3 bg-iinfo me-2`} />
                        <span>{assetDetailData?.vulnerabilities?.info}</span>
                      </div>
                      </div>
                    </div>

                    <div className='d-flex flex-column mt-1 p-2 pt-3 pb-3 overflow-auto'>
                      {assetDetailData?.vulnerabilities_list?.map(
                        (vulnerability: Vulnerability, index: number, arr: Vulnerability[]) => {
                          return (
                            <div
                              key={`assets detail list - ${vulnerability?.id}`}
                              className={`d-flex flex-column w-100 bg-secondary p-3 border border-box m-0 rounded-3 ${
                                index === arr.length - 1 ? null : 'mb-3'
                              }`}
                            >
                              <div className='d-flex flex-row justify-content-between align-items-center w-100 h-30px text-capitalize'>
                                <div className='d-flex flex-row w-100'>
                                  <div
                                    className={`w-15px h-15px rounded-3 border border-2 me-2 border-${
                                      vulnerability?.severity && `i${vulnerability?.severity}`
                                    }`}
                                  />
                                  {vulnerability?.severity}
                                  <div className='bg-dark w-1px min-h-20px gx-0 me-3 ms-3' />
                                  <div className={`w-auto p-0 m-0 text-uppercase text-gray-700`}>
                                    <ConvertDate
                                      date={vulnerability?.timestamp}
                                      jalali={vulnerability?.timestamp_jalali}
                                    />
                                  </div>
                                </div>

                                <button
                                  className={`col-auto border border-0  bg-secondary p-0 m-0`}
                                  onClick={() => {
                                    navigate({
                                      pathname: '/vulnerability-details',
                                      search: `?id=${vulnerability.id}`,
                                    })
                                  }}
                                >
                                  <Arr001 fill={'#fff'} className={'image_change_theme'} />
                                </button>
                              </div>
                              <div className={`w-100 h-auto d-flex align-items-center p-0`}>
                                {vulnerability.title}
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </div>
                </div>

                <div className='card'>
                <div className='card-body p-5'>
                  <h3 className='mb-5'>{intl.formatMessage({id: 'ScreenShot'})}</h3>
                  <div
                    data-bs-toggle={assetDetailData?.thumbnail && 'modal'}
                    data-bs-target={assetDetailData?.thumbnail && '#exampleModal'}
                    className='ratio ratio-16x9 rounded bg-light cursor-pointer'
                    style={{
                      backgroundImage: `url(${assetDetailData?.thumbnail || 'NoImage.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                    }}
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <ImageModal url={assetDetailData?.screen_shot || ''} />
        <HistoryModal
          data={assetDetailData?.scan_list || []}
          get={fetchAssetDetailData}
          selected={assetDetailData?.selected_scan || ''}
        />
      </>
    </>
  )
}

export default AssetDetails
