import React, {useState, useContext, useLayoutEffect} from 'react'
import axios from 'axios'
import ScanTable from './components/ScanTable'
// import ReactPaginate from 'react-paginate'
// import DAG from './components/DAG'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {ChangeUserStatus, SetDataScan, SetDataScanDAG} from '../../modules/auth'
// import ReactPaginate from 'react-paginate'
import {ColumnChart} from './components/ColumnChart'
import {LineChart} from './components/LineChart'
import Arr001 from '../components/Arr001'
import {TaskScan} from './components/TaskScan'
import {useIntl} from 'react-intl'
import {I18nContext} from '../../../_metronic/i18n/Metronici18n'
import Joyride, {CallBackProps} from 'react-joyride'
import usePermission from '../../../Utils/usePermission'
import {Button} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

/**
 * Represents the data structure for a chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values.
 *
 * @typedef {Object} ChartData
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {number[]} y - An array of numeric values corresponding to each x-axis label.
 */
const chartData = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13'],
  y: [
    77, 105, 87, 106, 11, 108, 63, 100, 66, 107, 88, 117, 86, 111, 88, 163, 60, 166, 77, 118, 87,
    186, 11, 118, 63, 110, 66, 117, 88, 117, 86, 111, 88, 113, 60, 116,
  ],
}

/**
 * Represents the data structure for a multi-series chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values for multiple series.
 *
 * @typedef {Object} MultiSeriesChartData
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {Array<{name: string, data: number[]}>} y - An array of series objects, each containing:
 *   @property {string} name - The name of the series.
 *   @property {number[]} data - An array of numeric values corresponding to each x-axis label for this series.
 */
const leftChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
    {
      name: 'string2',
      data: [3, 8, 11, 18, 25],
    },
  ],
}

/**
 * Represents the data structure for a single-series chart.
 * This object contains x-axis labels (dates) and corresponding y-axis values for one series.
 *
 * @type {Object}
 * @property {string[]} x - An array of date strings representing the x-axis labels.
 * @property {Array<{name: string, data: number[]}>} y - An array containing a single series object:
 *   @property {string} name - The name of the series.
 *   @property {number[]} data - An array of numeric values corresponding to each x-axis label for this series.
 */
const rightChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
  ],
}

export const Scan = () => {
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const lang = useContext(I18nContext)?.lang.dir
  const [loading, setLoading] = useState(false)
  const [idSelected, setIdSelected] = useState<null | string>(null)
  const scanData = useSelector((state: RootState) => state?.root?.data?.dataScan)

  const handlePageClick = (event: {selected: number}) => {
    fetchScanData(event.selected + 1)
  }

  const fetchScanData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page}`)
      errorHandling(data.status)
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScan(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (scanData?.expireTime && scanData.expireTime >= nowDate && scanData?.data) {
    } else {
      fetchScanData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <>
        <div className='position-relative d-flex gap-5 w-100'>
          {isPermission()}
          <div className='w-100 card'>
            <div className='d-flex w-100 gap-2'>
              <div className='h-100 ' style={{width: 'calc(100% - 300px)'}}>
                <div className='p-3 pt-5 h-100'>
                  <div className='d-flex justify-content-between w-100 h-30px'>
                    <h3>{intl.formatMessage({id: 'Total Scans'})}</h3>
                    <div
                      className='d-flex align-items-center gap-3'
                      // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                      style={{filter: 'blur(3px)'}}
                    >
                      <div className='d-flex justify-content-center align-items-center gap-1 bg-gray-300 p-2 w-40px h-30px'>
                        <div
                          className='rounded-pill w-8px h-8px'
                          style={{backgroundColor: '#F15B46'}}
                        ></div>
                        <span>18</span>
                      </div>
                      <div className='d-flex justify-content-center align-items-center gap-1 bg-gray-300 p-2 w-40px h-30px'>
                        <div
                          className='rounded-pill w-8px h-8px'
                          style={{backgroundColor: '#02694a'}}
                        ></div>
                        <span>18</span>
                      </div>
                    </div>
                  </div>
                  <div style={{filter: 'blur(3px)', height: 'calc(100% - 30px)'}}>
                    <ColumnChart chartData={chartData} />
                  </div>
                </div>
              </div>
              <div className=' p-2 pt-5' style={{width: 300}}>
                <div className=' w-100 '>
                  <div className='position-relative w-100  inner_box'>
                    <div className='d-flex flex-column gap-2  w-100 '>
                      <div className='py-2 p-3  w-100 card' style={{height: 'fit-content'}}>
                        <h2>{intl.formatMessage({id: 'Task Runs'})}</h2>
                        <div
                          className='d-flex align-items-center gap-5 mt-2 h-100px'
                          // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                          // style={{filter: 'blur(3px)'}}
                        >
                          <p
                            style={{
                              fontSize: 45,
                              margin: 0,
                              height: 'fit-content',
                              minWidth: 123,
                              textAlign: 'center',
                            }}
                          >
                            23
                          </p>

                          <LineChart chartData={leftChart} />
                        </div>
                      </div>
                      <div className='py-2 p-3  w-100 card' style={{height: 'fit-content'}}>
                        <h2>{intl.formatMessage({id: 'Average run time'})}</h2>
                        <div
                          className='d-flex align-items-center gap-5 mt-2 h-100px'
                          // style={{filter: 'blur(3px)'}}
                        >
                          <p
                            style={{
                              fontSize: 45,
                              margin: 0,
                              height: 'fit-content',
                              textWrap: 'nowrap',
                              minWidth: 123,
                              textAlign: 'center',
                            }}
                          >
                            32min
                          </p>
                          {/*
                           * Renders a line chart component using the provided chart data.
                           *
                           * @param {Object} props - The properties passed to the LineChart component.
                           * @param {MultiSeriesChartData} props.chartData - The data to be displayed in the line chart.
                           *                                                 It should conform to the MultiSeriesChartData type, which includes
                           *                                                 x-axis labels and corresponding y-axis values for multiple series.
                           * @returns {JSX.Element} A rendered LineChart component displaying the provided data.
                           */}
                          <LineChart chartData={rightChart} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className='mx-5' />
            <div className='h-100 '>
              {/*
               * Renders a table component displaying scan data with interactive features.
               *
               * @param {Object} props - The properties passed to the ScanTable component.
               * @param {boolean} props.getLoading - Indicates whether data is currently being loaded.
               * @param {function} props.setIdSelected - Function to set the ID of the selected scan.
               * @param {number | null} props.idSelected - The currently selected scan ID.
               * @param {Array<Object> | undefined} props.scanData - An array of scan data objects to be displayed in the table.
               * @param {function} props.getData - Function to fetch scan data, typically used for refreshing the table.
               * @returns {JSX.Element} A rendered ScanTable component.
               */}
              <ScanTable
                getLoading={loading}
                setIdSelected={setIdSelected}
                idSelected={idSelected}
                scanData={scanData?.data?.results}
                getData={fetchScanData}
              />
            </div>
            <div className='d-flex justify-content-end bg-body pb-4 rounded-bottom pe-4'>
              <ReactPaginate
                nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={scanData?.data?.total_pages || 1}
                forcePage={(scanData?.data?.current_page || 1) - 1}
                previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
        {/* Joyride Component */}
      </>
    </>
  )
}
