import React, {useState, useRef, useEffect} from 'react'
import {useField} from 'formik'

interface Option {
  label: string
  value: string | number
}

interface MultiSelectProps {
  name: string

  options: Option[] | []
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  name,

  options,
}) => {
  const [field, meta, helpers] = useField(name)
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const selectedValues = field.value || []

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleOptionClick = (optionValue: string | number) => {
    const newValues = selectedValues.includes(optionValue)
      ? selectedValues.filter((value: string | number) => value !== optionValue)
      : [...selectedValues, optionValue]
    helpers.setValue(newValues)
  }

  const handleRemoveOption = (e: React.MouseEvent, optionValue: string | number) => {
    e.stopPropagation()
    const newValues = selectedValues.filter((value: string | number) => value !== optionValue)
    helpers.setValue(newValues)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='mb-4'>
      <div className='position-relative' ref={dropdownRef}>
        {/* Main button */}
        <div
          className={`form-control d-flex flex-wrap gap-2 min-h-[38px] ${
            meta.touched && meta.error ? 'is-invalid' : ''
          }`}
          onClick={toggleDropdown}
          role='button'
          style={{cursor: 'pointer', minHeight: '38px'}}
        >
          {selectedValues.map((value: string | number) => {
            const option = options.find((opt) => opt.value === value)
            return (
              <span key={value} className='badge bg-primary d-flex align-items-center'>
                {option?.label}
                <button
                  type='button'
                  className='btn-close btn-close-white ms-2'
                  style={{fontSize: '0.65em'}}
                  onClick={(e) => handleRemoveOption(e, value)}
                ></button>
              </span>
            )
          })}
        </div>

        {/* Dropdown menu */}
        <div
          className={`dropdown-menu w-100 ${isOpen ? 'show' : ''}`}
          style={{maxHeight: '200px', overflowY: 'auto'}}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className='dropdown-item'
              onClick={() => handleOptionClick(option.value)}
              role='button'
            >
              <div className='form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={selectedValues.includes(option.value)}
                  onChange={() => {}} // Handled by div click
                  onClick={(e) => e.stopPropagation()}
                />
                <label className='form-check-label'>{option.label}</label>
              </div>
            </div>
          ))}
        </div>
      </div>

      {meta.touched && meta.error && <div className='invalid-feedback d-block'>{meta.error}</div>}
    </div>
  )
}

export default MultiSelect
