import React, {useEffect, useState} from 'react'
import Warning2 from '../images/Warning2'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import {RootState} from '../../../setup/redux/Store'
import {SetDataServices} from '../../modules/auth'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {KTIcon} from '../../../_metronic/helpers'
import {ServiceType} from '../../modules/types/service'
import useErrorHandling from '../../../Utils/useErrorHandling'
import EditModal from './Modal'
import './Services.scss'

// Tutorial steps remain the same

export default function Services () {
  // Existing state and hooks setup remains the same

  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const servicesData = useSelector((state: RootState) => state.root.data.dataServices)
  const [open, setOpen] = useState<null | ServiceType>(null)
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const intl = useIntl()
  const lang = useLang()?.lang.selectedLang
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()

  const steps = [
    {
      target: '.service-feature-one',
      content: intl.formatMessage({id: 'Joyride service'}),
    },
  ]
  // Existing fetchServices function remains the same
  const fetchServices = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/features/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataServices(data.data))
        if (isUserNew) {
          setTimeout(() => {
            setRun(true)
            setInd(0)
          }, 0)
        }
      } else {
        console.error('Error fetching services:', data)
      }
    } catch (e) {
      console.log('Service Fetch Error:', e)
    }
  }

  // Existing useEffect remains the same
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (!servicesData?.expireTime || servicesData.expireTime < nowDate) {
      fetchServices()
    } else {
      if (isUserNew) {
        setTimeout(() => {
          setRun(true)
          setInd(0)
        }, 0)
      }
    }
  }, [])

  // Existing handleJoyrideCallback remains the same
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data
    console.log('service', status)
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/scan-history')
    }
  }
  return (
    <div className='services-container py-5'>
      <div className=''>
        <div className='container'>
          <div className='row g-4 justify-content-center '>
            {servicesData?.data?.map((item, index) => (
              <div className={`col-12 col-md-6 col-lg-4 service-feature-one`} key={item.id}>
                <div className='service-card card h-100 shadow-sm '>
                  {/* Service Header */}
                  <div className='card-header bg-transparent border-0 p-4'>
                    <div className='d-flex align-items-center gap-3'>
                      <div className='service-icon-wrapper bg-primary rounded-3 p-2'>
                        <KTIcon iconName={item.icon_name} className='text-white service-icon' />
                      </div>
                      <h3 className='service-title mb-0'>
                        {lang === 'fa' ? item.name_fa : item.name}
                      </h3>
                    </div>
                  </div>

                  {/* Service Description */}
                  <div className='card-body p-4 '>
                    <div className='service-description-box border rounded-3 p-3'>
                      <div className='d-flex gap-3'>
                        <Warning2
                          className=' image_change_theme2'
                          svgClassName='service-warning-icon'
                        />
                        <div className='service-description'>
                          <p className='description-text mb-2'>
                            {lang === 'fa' ? item.description_fa : item.description}
                          </p>
                          <div className='service-feature-three'>
                            <button
                              className='btn btn-link p-0 text-primary'
                              type='button'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_service_details'
                              onClick={() => setOpen(item)}
                              aria-label={intl.formatMessage({id: 'show more'})}
                            >
                              {intl.formatMessage({id: 'show more'})}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Service Action */}
                  <div className='card-footer bg-transparent border-0 p-4 service-feature-tow'>
                    <div className='d-grid'>
                      {!item.is_available ? (
                        <button className='btn btn-primary' onClick={() => navigate('/ticket')}>
                          {intl.formatMessage({id: 'Request'})}
                        </button>
                      ) : (
                        <button className='btn btn-outline-primary' disabled>
                          {intl.formatMessage({id: 'You have this'})}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Details Modal */}
      <EditModal setProps={setOpen} props={open} />

      {/* Joyride Tour */}
      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={false}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{
          next: intl.formatMessage({id: 'next'}),
          skip: intl.formatMessage({id: 'Skip'}),
          close: intl.formatMessage({id: 'Close'}),
          last: intl.formatMessage({id: 'next'}),
          back: intl.formatMessage({id: 'Back'}),
        }}
        spotlightPadding={5}
        styles={{
          buttonClose: {display: 'none'},
          options: {
            zIndex: 10000,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      />
    </div>
  )
}
