import axios from 'axios'
import {useIntl} from 'react-intl'

// import {KTIcon} from '../../../helpers'


const SidebarFooter = () => {
 
  const intl = useIntl()


  const getReport = async (format: string) => {
    const data = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/assets/report/?type=${format}`
    )
    if (format === 'html') {
      const htmlContent = [`${data.data}`]
      const bl = new Blob(htmlContent, {type: 'text/html'})
      const a = document.createElement('a')
      a.href = URL.createObjectURL(bl)
      a.download = 'Report.html'
      a.hidden = true
      document.body.appendChild(a)
      a.click()
    } else {
      const element = document.createElement('a')
      const textFile = new Blob([JSON.stringify(data.data)], {type: 'text/plain'}) //pass data from localStorage API to blob
      element.href = URL.createObjectURL(textFile)
      element.download =
        format === 'csv' ? 'Report.csv' : format === 'docx' ? 'Report.doc' : 'Report.json'
      document.body.appendChild(element)
      element.click()
    }
  }
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'  >
      <div>
        <div className='d-flex justify-content-center align-items-center mx-auto dropdown'>
          <button
            className='w-75 h-40px btn btn-secondary dropdown-toggle overflow-hidden btn-custom'
            type='button'
            id='dropdownMenuButton'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span className='btn-label'>{intl.formatMessage({id: 'Report'})}</span>
            <span className='btn-icon'>{intl.formatMessage({id: 'R'})}</span>
          </button>
          <div className='dropdown-menu w-75' aria-labelledby='dropdownMenuButton'>
            <a className='dropdown-item' onClick={() => getReport('json')}>
              JSON
            </a>
            <a className='dropdown-item' onClick={() => getReport('csv')}>
              CSV
            </a>
            <a className='dropdown-item' onClick={() => getReport('docx')}>
              DOC
            </a>
            {/* <a className="dropdown-item" onClick={() => getReport('html')}>HTML</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {SidebarFooter}
