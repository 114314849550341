import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {PRICE_PLAN_CARD_DATA, PRICE_PLAN_TABLE} from '../../modules/auth/redux/DEMO_DATA'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import Modal from './Modal'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {SetDataPricePlans} from '../../modules/auth'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import InfoIcon from '../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Question from '../images/Question'
import Chat from '../images/Chat'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import Arr001 from '../components/Arr001'
import Check from '../images/Ckeck'
import {Plan, PlanType} from '../../modules/types/plans'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
///////////////////////////////////////////////////////////////
// Mock Plans
const basicPlan: Plan = {
  id: 'plan-basic-001',
  name: 'Basic Plan',
  category: 'individual',
  price: '99.00',
  discounted_price: '79.00',
  discount_amount: '20.00',
  is_discount_percentage: false,
  duration_days: 30,
  scan_limit: 100,
  description: 'Perfect for individual users with basic scanning needs',
  features: [
    'Scan up to 100 documents per month',
    'Convert to PDF',
    'Email support',
    'Basic OCR capabilities',
  ],
  is_purchasable: true,
  name_fa: 'طرح پایه',
  category_fa: 'فردی',
  description_fa: 'مناسب برای کاربران فردی با نیازهای اسکن اولیه',
  features_fa: ['اسکن تا 100 سند در ماه', 'تبدیل به PDF', 'پشتیبانی ایمیل', 'قابلیت های OCR پایه'],
}

const standardPlan: Plan = {
  id: 'plan-standard-001',
  name: 'Standard Plan',
  category: 'individual',
  price: '199000000',
  discounted_price: '169000000',
  discount_amount: '15',
  is_discount_percentage: true,
  duration_days: 30,
  scan_limit: 500,
  description: 'Enhanced scanning capabilities for regular users',
  features: [
    'Scan up to 500 documents per month',
    'Convert to PDF, Word, and Excel',
    'Priority email support',
    'Advanced OCR with text search',
    'Cloud storage integration',
  ],
  is_purchasable: true,
  name_fa: 'طرح استاندارد',
  category_fa: 'فردی',
  description_fa: 'قابلیت های پیشرفته اسکن برای کاربران معمولی',
  features_fa: [
    'اسکن تا 500 سند در ماه',
    'تبدیل به PDF، Word و Excel',
    'پشتیبانی ایمیل با اولویت',
    'OCR پیشرفته با قابلیت جستجوی متن',
    'ادغام با فضای ذخیره سازی ابری',
  ],
}

const nonprofitPlan: Plan = {
  id: 'plan-nonprofit-001',
  name: 'Nonprofit Plan',
  category: 'special',
  price: '599.00',
  discounted_price: '299.00',
  discount_amount: '50',
  is_discount_percentage: true,
  duration_days: 365,
  scan_limit: 10000,
  description: 'Discounted plan for registered nonprofit organizations',
  features: [
    'Annual license',
    'Scan up to 10000 documents per month',
    '15 user accounts',
    'Premium features at reduced cost',
    'Donation tracking tools',
    'Volunteer management',
    'Grant application templates',
  ],
  is_purchasable: false,
  name_fa: 'طرح غیرانتفاعی',
  category_fa: 'ویژه',
  description_fa: 'طرح تخفیف دار برای سازمان های غیرانتفاعی ثبت شده',
  features_fa: [
    'مجوز سالانه',
    'اسکن تا 10000 سند در ماه',
    '15 حساب کاربری',
    'ویژگی های حرفه ای با هزینه کاهش یافته',
    'ابزارهای ردیابی کمک های مالی',
    'مدیریت داوطلبان',
    'قالب های درخواست کمک مالی',
  ],
}

export const mockPlans = [basicPlan, standardPlan, nonprofitPlan]

export default function PricePlan () {
  const intl = useIntl()
  const lang = useLang()?.lang.selectedLang
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const pricePlanData = useSelector((state: RootState) => state.root.data.dataPricePlans)
  const [details, setDetails] = useState<Plan | null>(null)
  const [height, setHeight] = useState<number>()
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const [productSelectedIndex, setProductSelectedIndex] = useState<null | number>(null)
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const front = useRef<null | any>(null)
  const back = useRef<null | any>(null)
  const steps = [
    {
      target: '.service-feature-one',
      content: intl.formatMessage({id: 'Joyride Lang'}),
    },
    {
      target: '.service-feature-two',
      content: intl.formatMessage({id: 'Joyride plan'}),
    },
  ]
  /**
   * Handles the callback events from the Joyride tour component.
   * This function is responsible for managing the tour's completion or skipping,
   * and redirecting the user to the scan page when the tour ends.
   *
   * @param {CallBackProps} data - The callback data provided by Joyride.
   * @param {string} data.status - The current status of the tour ('finished' or 'skipped').
   *
   * @returns {void}
   */
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    // When the tour is finished, redirect to the scan Page
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/services')
    }
  }

  /**
   * Fetches price plans from the server and updates the application state.
   *
   * This asynchronous function sends a GET request to the server's plans endpoint,
   * handles the response, and dispatches the received data to update the application state.
   * It also includes error handling for invalid tokens and unsuccessful requests.
   *
   * @async
   * @function fetchPricePlans
   * @throws {Error} Logs any errors that occur during the fetch operation
   * @returns {Promise<void>} A promise that resolves when the operation is complete
   */
  const fetchPricePlans = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/plans/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPricePlans(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/plans/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  const [billingPeriod, setBillingPeriod] = useState('monthly')
  /**
   * Effect hook to manage price plan data fetching and new user onboarding.
   *
   * This effect runs once on component mount and performs the following tasks:
   * 1. Checks if the current price plan data is expired and fetches new data if necessary.
   * 2. Initiates the onboarding process for new users.
   *
   * @effect
   * @param {void}
   * @returns {void}
   */
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (pricePlanData?.expireTime && pricePlanData.expireTime >= nowDate) {
    } else {
      fetchPricePlans()
    }
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useLayoutEffect(() => {
    productSelectedIndex === null
      ? setHeight(front?.current?.offsetHeight)
      : setHeight(back?.current?.offsetHeight)
  }, [front, back, productSelectedIndex])

  return (
    <div
      className='mb-10 overflow-hidden'
      style={{
        minHeight: height || '100vh',
      }}
    >
      <div
        className='w-50px h-50px service-feature-one'
        style={
          lang !== 'fa'
            ? {position: 'absolute', top: -64, zIndex: 10000, right: 70}
            : {position: 'absolute', top: -64, zIndex: 10000, left: 90}
        }
      ></div>
      <div
        className='position-relative w-100 inner_box'
        style={
          productSelectedIndex !== null
            ? {transform: 'rotateY(180deg)'}
            : {transform: 'rotateY(0deg)'}
        }
      >
        <div className='p-5 w-100 vh-100 card front_card shadow-none' ref={front}>
          <div className='py-4'>
            <h1 className='mb-4 fw-bold text-center fs-2hx text-primary'>
              {intl.formatMessage({id: 'Choose Your Product'})}
            </h1>
            <div className='fw-semibold text-center text-gray-600 fs-5 d-flex justify-content-center align-items-center gap-2'>
              {intl.formatMessage({id: 'If you need more info about our products, please check'})}
              <Link
                to={'/'}
                className='fw-bold link-primary text-decoration-none d-flex align-items-center'
              >
                {intl.formatMessage({id: 'Products Guidelines'})}
                <i className='ki-arrow-right ms-1 fs-6'></i>
              </Link>
            </div>
          </div>

          <div className='align-items-stretch my-8 w-100 g-4 row service-feature-two'>
            {pricePlanData.data?.map((items, ind: number) => (
              <div className='col-lg-4 col-md-6' key={ind}>
                <div
                  className='h-100 card service-feature-4  shadow-sm hover-elevate-up '
                  style={{
                    border: '1px solid #0095E8',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    setProductSelectedIndex(ind)
                  }}
                >
                  <div className='card-body p-0'>
                    <div className='d-flex flex-column h-100'>
                      {/* Card Header with Accent Color Bar */}
                      <div
                        className='card-header border-0 pt-9 pb-4'
                        style={{
                          background: `linear-gradient(90deg, #3E97FF 0%, #0095E8 100%)`,
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        {/* Abstract Shape Overlay */}
                        <div
                          style={{
                            position: 'absolute',
                            width: '150px',
                            height: '150px',
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '50%',
                            top: '-50px',
                            right: '-50px',
                          }}
                        ></div>

                        <div className='d-flex flex-column align-items-center'>
                          <h1 className='mb-2 fw-bolder text-white text-capitalize'>
                            {lang === 'fa' ? items?.name_fa : items.name}
                          </h1>
                        </div>
                      </div>

                      {/* Card Content */}
                      <div className='card-body px-9 pt-6 pb-5 d-flex flex-column'>
                        <div
                          className='mb-5 fw-semibold text-gray-700 text-center overflow-hidden'
                          style={{
                            height: '75px',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {lang === 'fa' ? items?.description_fa : items.description}
                        </div>

                        {/* Features Section */}
                        <div className='separator separator-dashed my-6'></div>

                        <div className='w-100 flex-grow-1'>
                          {(lang === 'fa'
                            ? items?.features_fa || items.features
                            : items.features
                          ).map((item, index) => (
                            <div className='d-flex align-items-center mb-4' key={index}>
                              <div className='flex-shrink-0 me-3'>
                                <i className='ki-check-circle ki-duotone fs-2 text-success'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                              </div>
                              <span className='flex-grow-1 fw-semibold text-gray-800 fs-6'>
                                {intl.formatMessage({id: item})}
                              </span>
                            </div>
                          ))}
                        </div>

                        {/* Action Button - Fixed at Bottom */}
                        <div className='text-center mt-auto pt-5'>
                          <button
                            className='px-8 py-3 rounded-pill btn btn-primary btn-sm hover-scale fw-bold'
                            type='button'
                          >
                            {intl.formatMessage({id: 'Select'})}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='p-5 w-100 back_card_y card' ref={back}>
          {/* <div className=''> */}
          {/* title */}
          <div className='service-feature-one'>
            <h1 className='mb-5 fw-bold text-center fs-2hx'>
              {' '}
              {intl.formatMessage({id: 'Choose Your Plan'})}
            </h1>
            <div className='fw-semibold text-center text-gray-600 fs-5'>
              {intl.formatMessage({id: 'If you need more info about our pricing, please check'})}{' '}
              <Link to={'/'} className='fw-bold link-primary'>
                {intl.formatMessage({id: 'Pricing Guidelines'})}
              </Link>
              .
            </div>
          </div>
          {/* plan card */}
          <div className='pricing-section py-12 '>
            <div className='container'>
              {/* Back button - preserved from original */}
              {productSelectedIndex !== null && (
                <button
                  className='border border-1 rounded-pill position-absolute bg-transparent p-2'
                  style={{
                    top: '10px',
                    left: '10px',
                    zIndex: 100,
                  }}
                  onClick={() => setProductSelectedIndex(null)}
                >
                  {/* <ArrowRight className="rotate-180" /> */}
                  <Arr001 fill={'#fff'} className={'image_change_theme'} />
                </button>
              )}

              {/* Pricing Header */}
              <div className='text-center mb-10'>
                <div className='d-flex align-items-center justify-content-center mt-6' dir='ltr'>
                  <span
                    className={`me-3 fw-semibold ${
                      billingPeriod === 'monthly' ? '' : 'text-muted'
                    }`}
                  >
                    {intl.formatMessage({id: 'Monthly'})}
                  </span>
                  <div className='form-check form-switch mx-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      onChange={() =>
                        setBillingPeriod(billingPeriod === 'monthly' ? 'yearly' : 'monthly')
                      }
                      checked={billingPeriod === 'yearly'}
                    />
                  </div>
                  <span
                    className={`me-2 fw-semibold ${billingPeriod === 'yearly' ? '' : 'text-muted'}`}
                  >
                    {intl.formatMessage({id: 'Yearly'})}
                  </span>
                  {/* <span className='badge bg-success-subtle text-success ms-2'>Save 20%</span> */}
                </div>
              </div>

              {/* Pricing Cards */}
              <div className='row g-4 justify-content-center'>
                {mockPlans?.map((plan, ind) => (
                  <div
                    className='col-lg-4 col-md-6'
                    key={plan.id}
                    style={{
                      filter: !pricePlanData?.data ? 'blur(3px)' : 'blur(0px)',
                    }}
                  >
                    <div
                      className={`card h-100 border-0 shadow-sm hover-elevate-up ${
                        ind === 1 ? 'bg-light-success' : 'bg-gray-100'
                      }`}
                      style={{
                        transform: ind === 1 ? 'scale(1.02)' : 'scale(1)',
                        zIndex: ind === 1 ? 100 : 1,
                        borderRadius: '12px',
                      }}
                    >
                      {/* Card Header */}
                      <div className='card-header flex-column text-center bg-transparent border-0 pt-8'>
                        <h2 className='card-title fw-bold mb-0 text-capitalize'>
                          {lang === 'fa' ? plan?.name_fa : plan.name}
                        </h2>

                        {/* Pricing */}
                        <div className='text-center my-5'>
                          {plan?.is_purchasable ? (
                            <>
                              {plan.discounted_price !== plan.price && (
                                <span
                                  className='fw-bold text-muted me-2 d-block'
                                  style={{
                                    textDecoration: 'line-through',
                                    fontSize: 18,
                                  }}
                                >
                                  {new Intl.NumberFormat().format(+plan.price / 10 || 0)}
                                </span>
                              )}
                              <div className='d-flex align-items-end justify-content-center'>
                                <h1 className='display-4 fw-bold  mb-0'>
                                  {plan.discounted_price
                                    ? new Intl.NumberFormat().format(
                                        +plan.discounted_price / 10 || 0
                                      )
                                    : new Intl.NumberFormat().format(+plan.price / 10 || 0)}
                                </h1>
                                <span className='mb-2 ms-1  fw-semibold text-nowrap'>
                                  {intl.formatMessage({id: 'IR-T'})}
                                </span>
                                <span className='ms-2 mb-2 text-muted fw-semibold fs-7 '>
                                  /
                                  <span className='text-capitalize text-nowrap'>
                                    {plan.duration_days} {intl.formatMessage({id: 'day'})}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <h1 className='display-4 fw-bold mb-0'>
                              {intl.formatMessage({id: 'Enterprise'})}
                            </h1>
                          )}
                        </div>

                        {/* Description */}
                        <p className='card-text text-muted mb-6'>
                          {lang === 'fa' ? plan?.description_fa : plan.description}
                        </p>
                      </div>

                      {/* Card Body */}
                      <div className='card-body d-flex flex-column px-5'>
                        {/* Features Section */}
                        <div className='mb-7 flex-grow-1'>
                          {(lang === 'fa'
                            ? plan?.features_fa || plan.features
                            : plan.features
                          )?.map((feature, index) => (
                            <div className='d-flex align-items-center mb-3' key={index}>
                              <Check className='svg-icon-success me-2' />
                              <span className='fw-semibold text-gray-700 fs-6'>
                                {intl.formatMessage({id: feature})}
                              </span>
                            </div>
                          ))}
                        </div>

                        {/* Action Button */}
                        <div className='text-center mt-auto pt-5'>
                          {plan.is_purchasable ? (
                            <button
                              onClick={() => setDetails(plan)}
                              className={`btn ${
                                ind === 1 ? 'btn-success' : 'btn-outline-primary'
                              } btn-lg w-100 rounded-pill`}
                              type='button'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_edit'
                              disabled={!pricePlanData.data?.length}
                            >
                              {intl.formatMessage({id: 'Start now'})}{' '}
                              <Arr001 fill={'#fff'} className={'image_change_theme'} />
                            </button>
                          ) : (
                            <button
                              className='btn btn-dark btn-lg w-100 rounded-pill'
                              onClick={() => navigate('/ticket')}
                            >
                              {intl.formatMessage({id: 'Contact sales'})}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* plan table */}
          <div className='service-feature-5'>
            <table className='mt-7 align-middle gy-7 table table-striped'>
              <tbody>
                <tr>
                  <th className='card-rounded-start'>
                    <div className='d-flex align-items-center fw-bolder fs-3 ps-9'>
                      {intl.formatMessage({id: 'Feature'})}
                    </div>
                  </th>
                  {PRICE_PLAN_CARD_DATA[0].plans.map((item, ind, arr) => (
                    <td key={ind} className={`${ind === arr.length - 1 && 'card-rounded-end'}`}>
                      <div className='d-flex flex-root justify-content-center fw-bolder text-capitalize fs-3'>
                        {item.name}
                      </div>
                    </td>
                  ))}
                </tr>

                {PRICE_PLAN_TABLE.map((item, ind) => (
                  <tr key={ind}>
                    <th className={'card-rounded-start'}>
                      <div
                        className='d-flex align-items-center gap-2 fw-bolder fs-3 ps-9'
                        style={item.group ? {color: 'red'} : {}}
                      >
                        {intl.formatMessage({id: item.title})}
                        {!item.group && (
                          <div>
                            <span
                              data-tooltip-id={'my-tooltip' + ind}
                              data-tooltip-content={intl.formatMessage({
                                id: item.description,
                              })}
                            >
                              <Question className='image_change_theme2 svg-icon-2x' />
                            </span>
                            <ReactTooltip
                              id={'my-tooltip' + ind}
                              style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                    {item.data.map((i, index, arr) => (
                      <td
                        key={index}
                        className={`${
                          index === arr.length - 1 ? 'card-rounded-end ' : 'no_reduce'
                        }`}
                      >
                        <div className='d-flex flex-root justify-content-center'>
                          {i === true ? (
                            <i className='text-success fs-1 ki-check-circle ki-duotone'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : i === false ? (
                            <i className='fs-1 ki-cross-circle ki-duotone'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : (
                            <span>{intl.formatMessage({id: i || ' '})}</span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* Joyride Component */}

      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={false}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{
          next: intl.formatMessage({id: 'next'}),
          skip: intl.formatMessage({id: 'Skip'}),
          close: intl.formatMessage({id: 'Close'}),
          last: intl.formatMessage({id: 'next'}),
          back: intl.formatMessage({id: 'Back'}),
        }}
        spotlightPadding={5}
        styles={{
          buttonClose: {display: 'none'},
          options: {
            zIndex: 10000,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      />

      {/*
       * Renders a Modal component for displaying and managing plan details.
       *
       * @param {Object} props - The properties passed to the Modal component.
       * @param {PlanType | null} props.details - The current plan details to be displayed in the modal.
       *                                          If null, no plan is currently selected.
       * @param {React.Dispatch<React.SetStateAction<PlanType | null>>} props.setDetails - A function to update the details state.
       *                                                                                   Used to clear the modal when it's closed.
       * @returns {JSX.Element} A Modal component for displaying and managing plan details.
       */}
      <Modal details={details} setDetails={setDetails} />
    </div>
  )
}
