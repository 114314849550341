import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  ThemeModeSwitcher,
} from '../../../partials/index'
import {useLayout} from '../../core'
import blank from '../../../../app/pages/images/blank.png'
import {Languages} from '../../../partials/layout/header-menus/Languages'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {ChangeNotificationStatus, ChangeUserHasNewNotification} from '../../../../app/modules/auth'
import {useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const dispatch = useDispatch()
  const HasNewNotification = useSelector(
    (state: RootState) => state.root.user?.has_new_notification
  )
  const isNotification = useSelector((state: RootState) => state.root.newNotification)
  const [isHover, setIsHover] = useState(false)
  let time: NodeJS.Timeout
  useEffect(() => {
    if (isHover) {
      if (isNotification || HasNewNotification) {
        time = setTimeout(() => {
          if (isNotification) {
            dispatch(ChangeNotificationStatus(false))
          }
          if (HasNewNotification) {
            dispatch(ChangeUserHasNewNotification(false))
          }
        }, 2000)
      }
    } else {
      clearTimeout(time)
    }
    return () => {
      clearTimeout(time)
    }
  }, [isHover])
  return (
    <div className='app-navbar flex-shrink-0 '>
      <div
        className={clsx('app-navbar-item ', itemClass)}
        onMouseLeave={() => {
          setIsHover(false)
        }}
        onMouseEnter={() => {
          setIsHover(true)
        }}
      >
        <div
          data-kt-menu-trigger="{default: 'hover'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={`position-relative ${btnClass}`}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
          {(HasNewNotification || isNotification) && (
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          )}
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <Languages toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'hover'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={blank} alt='user' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
